import client from '../client';

export const GET_RECIPIENT_BY_ID = 'GET_RECIPIENT_BY_ID';
export const GET_RECIPIENT_BY_ID_SUCCESS = 'GET_RECIPIENT_BY_ID_SUCCESS';
export const GET_RECIPIENT_BY_ID_FAILURE = 'GET_RECIPIENT_BY_ID_FAILURE';

export const GET_RECIPIENT_BY_ID_AND_EMAIL = 'GET_RECIPIENT_BY_ID_AND_EMAIL';
export const GET_RECIPIENT_BY_ID_AND_EMAIL_SUCCESS = 'GET_RECIPIENT_BY_ID_AND_EMAIL_SUCCESS';
export const GET_RECIPIENT_BY_ID_AND_EMAIL_FAILURE = 'GET_RECIPIENT_BY_ID_AND_EMAIL_FAILURE';

export const UNSUBSCRIBE = 'UNSUBSCRIBE';
export const UNSUBSCRIBE_SUCCESS = 'UNSUBSCRIBE_SUCCESS';
export const UNSUBSCRIBE_FAILURE = 'UNSUBSCRIBE_FAILURE';

export const getRecipientById = (recipientId) => {
    return ({
        type: GET_RECIPIENT_BY_ID,
        promise: client.get(`recipients/get-by-id/${recipientId}`),
    });
}

export const getRecipientByIdAndEmail = (recipientId, email) => {
    return ({
        type: GET_RECIPIENT_BY_ID_AND_EMAIL,
        promise: client.get(`recipients/get-by-id-and-email/${recipientId}/${email}`),
    });
}

export const unsubscribe = values => ({
    type: UNSUBSCRIBE,
    promise: client.post('recipients/unsubscribe', values),
  });