import React from 'react';
import Svg, { Path } from 'svgs';

const PremiumBanked = ({ size, fill }) => {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={size || 24}
      viewBox="0 0 88 95"
    >
      <Path
        fill={fill || '#000'}
        fillRule="evenodd"
        d="M49.1 0l-5.299 5.3-5.3-5.3L34.6 4l6.401 6.4v10.7L33.7 13.9l-3.9 4 11.201 11.201v13.5L29.2 35.9l-4-15.3-5.499 1.4 2.7 9.9-9.301-5.4-2.399-8.8-5.4 1.501 1.899 7.2L0 28.3l1.501 5.401L10.3 31.4l9.301 5.4-9.9 2.6 1.399 5.4 15.3-4.099L38.2 47.5l-11.8 6.801L11.1 50.2l-1.399 5.4 9.9 2.6-9.301 5.4-8.799-2.3L0 66.701 7.2 68.6l-1.899 7.2 5.4 1.5 2.399-8.799 9.301-5.401-2.7 9.901 5.499 1.4 4-15.301 11.801-6.699v13.5L29.8 77.1l3.9 4 7.301-7.199V84.6L34.6 91.001 38.501 95l5.3-5.3L49.1 95l3.901-3.999-6.4-6.401V73.901L53.9 81.1l3.9-4-11.199-11.199v-13.5L58.4 59.1l4 15.301 5.5-1.4-2.699-9.901 9.3 5.401L76.9 77.3l5.4-1.5-1.9-7.2 7.2-1.899-1.5-5.401-8.799 2.3-9.3-5.4 9.899-2.6-1.4-5.4-15.3 4.101L49.4 47.5l11.8-6.799L76.5 44.8l1.4-5.4-9.899-2.6 9.3-5.4 8.799 2.301 1.5-5.401-7.2-1.899 1.9-7.2-5.4-1.501-2.399 8.8-9.3 5.4L67.9 22l-5.5-1.4-4 15.3-11.799 6.701v-13.5L57.8 17.9l-3.9-4-7.299 7.2V10.4l6.4-6.4z"
      />
    </Svg>
  );
};

export default PremiumBanked;
