import { GET_RECIPIENT_BY_ID_SUCCESS, GET_RECIPIENT_BY_ID_AND_EMAIL_SUCCESS, UNSUBSCRIBE_SUCCESS, UNSUBSCRIBE_FAILURE } from './recipient-actions';

export default (state = { Id: null, CountryCode: '', Email: ''}, action) => {

  if (action.type === GET_RECIPIENT_BY_ID_SUCCESS || action.type ===GET_RECIPIENT_BY_ID_AND_EMAIL_SUCCESS) {
    return action.json;
  }

  if (action.type === UNSUBSCRIBE_SUCCESS) {
    return true;
  }

  if (action.type === UNSUBSCRIBE_FAILURE) {
    return false;
  }

  return state ? state: {};
};
