import React, { useEffect, useRef, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import store from 'store2';

import DonorCard from './DonorCard';
import DonorListItem from './DonorListItem';
import DonorLoadMoreButton from './DonorLoadMoreButton';
import DonorNav from './DonorNav';
import DonorNoResults from './DonorNoResults';
import DonorSearchButton from './DonorSearchButton';
import DonorListContactUsButton from './DonorListContactUsButton';
import Loading from '../../common/components/Loading';
import { useQuery } from '../../common/utils/hooks';
import { searchDonors } from '../../redux/donor-actions';
import { getRecipientById } from '../../redux/recipient-actions';

const PAGE_SIZE = 18;

const DonorList = () => {
  const storedSearch = store.get('loginSearch');
  const dispatch = useDispatch();
  const [query, setQuery] = useQuery(storedSearch);
  const { mode } = query.toJS();
  const profileId = useRef(query.get('profileId'));
  const [lastQuery, setLastQuery] = useState(null);

  const recipient = useSelector(({ recipient }) => recipient);
  const [recipientId, loggedIn] = useSelector(
    ({ user }) => [user.getIn(['data', 'deleted']), user.get('loggedIn')],
    shallowEqual
  );

  const [recipientFound, setRecipientFound] = useState(false);

  ///
  /// Find the current recipient (if there is one) - and change the available_in selection accordingly
  ///
  useEffect(() => {
    if (loggedIn) {
      ///
      /// We only want to execute this if we don't already have a recipient
      ///
      if (!recipientFound) {
        dispatch(getRecipientById(recipientId));
      }

      setupAvailableInFilterForRecipient();
    }
    else {
      setRecipientFound(false);
    }

    ///
    /// Look to see if there is a recipient logged in - and if they have a defined country of residence
    ///
    /// If they do - and there is a filter for "available_in" then overwrite it. If no current filter
    /// then add one for this recipient's country of residence.
    ///
    function setupAvailableInFilterForRecipient() {
      if (!recipientFound) {
        if (recipient.Id) {
          setRecipientFound(true);
          console.log(recipient);
          if (recipient.Id && ['US', 'AU', 'CA', 'UK'].includes(recipient.CountryCode)) {
            console.log('Setting recipient');

            let countries = query.get('available_in');

            if (countries) {
              setQuery(query.delete('available_in'));
            }
            setQuery(query.set('available_in', recipient.CountryCode));
          }
        }
      }
    }
  }, [dispatch, query, recipientId, setQuery, recipient, loggedIn, recipientFound]);

  useEffect(() => {

    if (profileId.current === query.get('profileId')) {
      ///
      /// Only run the search if the current query is different from the previous one - this prevents double searches when the recpient county-specific
      /// filter is set programatically.
      ///
      if (JSON.stringify(lastQuery) !== JSON.stringify(query))
      {
        console.log('Searching ....');
        setLastQuery(query);        
        dispatch(searchDonors(query, PAGE_SIZE));
      }
    }  
  }, [dispatch, query, setQuery, recipient, recipientId, loggedIn, recipientFound, lastQuery]);


  useEffect(() => {
    profileId.current = query.get('profileId');
    console.log(profileId.current);
  }, [profileId, query]);

  const [donors, firstPage, lastPage, loaded, total] = useSelector(
    ({ donors: { list } }) => [
      list.get('data'),
      list.get('firstPage'),
      list.get('lastPage'),
      list.get('loaded'),
      list.get('total'),
    ],
    shallowEqual
  );

  let DonorComponent = DonorCard;

  if (mode === 'list') {
    DonorComponent = DonorListItem;
  }

  return (
    <div className="donor-search">
      <div className="donor-search__row margin-bottom__m">
        <DonorListContactUsButton />
      </div>
      <div className="donor-search--header">
        <h1 className="brandPurple">Donor Search</h1>
        <DonorSearchButton />
      </div>
      <DonorNav />
      {firstPage > 0 && <DonorLoadMoreButton buttonText="Load Previous Results" from={firstPage - PAGE_SIZE} />}
      {loaded && (
        <div
          className={cn('donor-search__donor-list', {
            'donor-search__donor-list--grid': !mode,
            'donor-search__donor-list--list': mode === 'list',
          })}
        >
          {donors.map(donor => (
            <DonorComponent donor={donor} key={donor.get('donor_id')} />
          ))}
        </div>
      )}
      {loaded && !total && <DonorNoResults />}
      {!loaded && (
        <div className="loading-padded">
          <Loading />
        </div>
      )}
      {total > donors.size && <DonorLoadMoreButton from={lastPage + PAGE_SIZE} />}
    </div>
  );
};

export default DonorList;
