import React, { useMemo } from 'react';

import DonorFavorite from './DonorFavorite';

import Search from '../../common/components/icons/search';
import { usePhotos, useQuery } from '../../common/utils/hooks';
import { colors } from '../../app-theme';

import OptionTypeIcon from '../../common/components/OptionTypeIcon';

const getBloodTypeText = (bloodType, bloodTypeRh) => {
  if (bloodType === 'Unknown') {
    return 'Blood Type Unknown';
  } else if (bloodTypeRh === 'Unknown') {
    return `Blood Type ${bloodType}`;
  } else {
    return `Blood Type ${bloodType} ${bloodTypeRh}`;
  }
};

const DonorListItem = ({ donor }) => {
  const [query, setQuery] = useQuery();
  const donorData = useMemo(() => donor.toJS(), [donor]);

  const {
    primary: { smallPlacholder, thumb },
  } = usePhotos(donor);

  return (
    <div className="donor-list-item" onClick={() => setQuery(query.set('profileId', donorData.donor_id))}>
      <div className="donor-list-item__profile-img">
        <div style={{ backgroundImage: `url(${thumb}), url(${smallPlacholder})`, backgroundSize: 'contain' }} />
        {donorData.coming_soon && <span className="donor-status coming-soon">coming soon</span>}
        {!donorData.active && <span className="donor-status inactive">inactive</span>}
      </div>
      <div className="donor-list-item__details">
        <div className="donor-list-item__details__top">
          <h4>ID: {donorData.donor_id}</h4>
          <DonorFavorite donorId={donorData.donor_id} favorited={donorData.favorited} />
        </div>

        <div className="donor-list-item__details__mid">
          {donorData.ethnicity && <h5>{donorData.ethnicity}</h5>}
          {donorData.eye_color && <span>{donorData.eye_color} Eyes</span>}
          {donorData.hair_color && <span>{donorData.hair_color} Hair</span>}
          {donorData.height && <span>{donorData.height}</span>}
          {donorData.weight_and_units && <span>{donorData.weight_and_units}</span>}
          {donorData.blood_type && <span>{getBloodTypeText(donorData.blood_type, donorData.blood_type_rh)}</span>}
        </div>
        <div className="donor-list-item__details__bottom">
          {donorData.banked_eggs && (
            <div className="donor-list-item__charm">
              <OptionTypeIcon type="premium" showName size={20} position="top" />
            </div>
          )}
          {donorData.is_baby_bundle && (
            <div className="donor-list-item__charm">
              <OptionTypeIcon type="baby-bundle" showName size={20} position="top" />
            </div>
          )}
          {donorData.is_family_bundle && (
            <div className="donor-list-item__charm">
              <OptionTypeIcon type="family-bundle" showName size={20} position="top" />
            </div>
          )}
           {donorData.is_banked_custom_choice && (
            <div className="donor-list-item__charm">
              <OptionTypeIcon type="banked-custom-choice" showName size={20} position="top" />
            </div>
          )}
          {donorData.active && (
            <div className="donor-list-item__view-button button button--has-icon button--size--small button--white">
              <Search size={20} fill={colors.brandPurple} />
              view
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DonorListItem;
