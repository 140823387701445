import { Component } from 'react';
import store from 'store2';

import config from '../../config';

const { domain, postLogoutRedirectUri } = config.auth;

export default class Logout extends Component {
  componentDidMount() {
    store.clearAll();

    window.location.href = `${domain}/user/logout?post_logout_redirect_uri=${postLogoutRedirectUri}`;
  }

  render() {
    return null;
  }
}
