import React, { Children, useCallback, useRef, useState } from 'react';
import { Iterable, List } from 'immutable';
import PropTypes from 'prop-types';
import cn from 'classnames';
import isFunction from 'lodash/isFunction';

import { useEventListener, useQuery } from '../../common/utils/hooks';
import CheckCircle from '../../common/components/icons/check-circle';
import { colors } from '../../app-theme';
import ChevronRight from '../../common/components/icons/chevron-right';
import DonorTraitSelectorOptionsList from './DonorTraitSelectorOptionsList';
import DonorTraitSelectorSearchableList from './DonorTraitSelectorSearchableList';

const DonorTraitSelector = ({ children, className, isSearchable, label, noneSelectedLabel, plural, trait }) => {
  const wrapperRef = useRef(null);
  const [query, setQuery] = useQuery();
  const [expanded, setExpanded] = useState();

  const handler = useCallback(
    e => {
      if (!wrapperRef.current.contains(e.target)) {
        setExpanded(false);
      }
    },
    [setExpanded]
  );

  useEventListener('mousedown', handler, document);

  let values = query.get(trait, new List());

  if (!Iterable.isIterable(values)) {
    values = new List([values]);
  }

  const options = Children.map(children, ({ props: { label, value } }) => ({ label, value }));

  const allSelected = values.size === options.length;

  const labelFunc = (size, pluralize) => {
    let value = isFunction(label) ? label() : label;

    if (pluralize) {
      value += '(s)';
    }

    return value;
  };

  const ListComponent = isSearchable ? DonorTraitSelectorSearchableList : DonorTraitSelectorOptionsList;

  return (
    <div ref={wrapperRef} className={cn('trait-selector', className)} onClick={() => setExpanded(!expanded)}>
      <div className="trait-selector__scaffold">
        <div className={cn('trait-selector__value', values.size > 2 && !allSelected && 'trait-selector__value-small')}>
          {(values.size === 0 || allSelected) && noneSelectedLabel}
          {!allSelected &&
            values
              .take(3)
              .map(value => options.find(option => option.value === value).label)
              .join(', ')}
          {values.size > 3 && !allSelected && `, ${values.size - 3} more`}
        </div>
        <div className="trait-selector__label">
          {labelFunc(values.size)}{' '}
          {values.size > 0 && (
            <span>
              (
              <span
                className="trait-selector__clear-all"
                onClick={e => {
                  e.stopPropagation();
                  e.nativeEvent.stopImmediatePropagation();
                  setQuery(query.delete(trait).delete('from'));
                }}
              >
                Clear All
              </span>
              )
            </span>
          )}
        </div>
      </div>
      <div className="trait-selector__arrow">
        <ChevronRight size={32} fill={colors.brandPurple} />
      </div>
      <ListComponent
        children={children}
        expanded={expanded}
        labelFunc={labelFunc}
        onClear={() => setQuery(query.delete(trait).delete('from'))}
        plural={plural}
        trait={trait}
        values={values}
        close={() => setExpanded(false)}
      />
    </div>
  );
};

DonorTraitSelector.defaultProps = { noneSelectedLabel: 'Any', plural: false };

export default DonorTraitSelector;

export const DonorTrait = ({ children, label, trait, value, values }) => {
  const [query, setQuery] = useQuery();

  const selected = values.includes(value);

  return (
    <div
      className={cn('trait-selector__option', { selected })}
      key={value}
      onClick={e => {
        let search;

        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();

        if (!selected) {
          search = query.set(trait, values.push(value));
        } else {
          search = query.set(
            trait,
            values.filter(val => val !== value)
          );
        }

        setQuery(search.delete('from'));
      }}
    >
      {selected && <CheckCircle fill={colors.brandPurple} />} {children} {label}
    </div>
  );
};

DonorTrait.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};
