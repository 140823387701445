import React, { useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { pdf } from '@react-pdf/renderer';
import { List } from 'immutable';
import cn from 'classnames';

import DonorProfileFamily from './DonorProfileFamily';
import DonorProfileGeneticReport from './DonorProfileGeneticReport';
import DonorProfileMedicalHistory from './DonorProfileMedicalHistory';
import DonorProfileOverview from './DonorProfileOverview';
import DonorProfilePhotos from './DonorProfilePhotos';
import DonorProfileNotes from './DonorProfileNotes';
import DonorProfilePdf from './DonorProfilePdf';
import { getDonorProfile } from '../../redux/donor-actions';

import Button from '../../common/components/buttons/button';
import Loading from '../../common/components/Loading';
import LoadingWhite from '../../common/components/LoadingWhite';
import OptionTypeIcon from '../../common/components/OptionTypeIcon';
import DonorView from '../../common/components/icons/donor-view';
import MedicalView from '../../common/components/icons/medical-view';
import FamilyView from '../../common/components/icons/family-view';
import GeneticView from '../../common/components/icons/genetic-view';
import Print from '../../common/components/icons/print-o';
import QuestionCircle from '../../common/components/icons/question-circle';
import { useQuery } from '../../common/utils/hooks';
import { colors } from '../../app-theme';

const DonorProfileModal = ({ setShowingContactUs }) => {
  const [currentTab, setCurrentTab] = useState('overview');
  const [isGeneratingPdf, setGeneratingPdf] = useState(false);
  const [profileId, setProfileId] = useState(null);
  const dispatch = useDispatch();
  const [query] = useQuery();
  const [donor, loaded, loading] = useSelector(
    ({ donors: { profile } }) => [profile.get('data'), profile.get('loaded'), profile.get('loading')],
    shallowEqual
  );
  const loggedIn = useSelector(({ user }) => user.get('loggedIn'));
  const locale = useSelector(({ locale }) => locale);

  const donorData = useMemo(() => donor.toJS(), [donor]);
  useEffect(() => setProfileId(query.get('profileId')), [query]);

  useEffect(() => {
    if (loggedIn && profileId) {
      dispatch(getDonorProfile(profileId));
      setCurrentTab('overview');
    }
  }, [dispatch, loggedIn, profileId, setCurrentTab]);

  if (!loggedIn || !loaded || loading || !profileId) {
    return (
      <div className="loading-centered">
        <Loading />
      </div>
    );
  }

  return (
    <div className="donor-profile">
      <div className="donor-profile__left-container">
        <DonorProfilePhotos donor={donor} />
        <div className="donor-profile__contact">
          <p>
            <strong>Contact us</strong> for more information or to move forward with this donor.
          </p>
          <Button buttonType="secondary" onClick={() => setShowingContactUs(true)}>
            Contact Us
          </Button>
        </div>
        {donorData.favorited ? <DonorProfileNotes favorite={donorData.favorited} /> : null}
      </div>
      <div className="donor-profile__right-container">
        <div className="donor-profile__header">
          <div>
            <h3>Donor ID: {donorData.donor_id}</h3>
            <div className="donor-profile__subheader">
              {donorData.banked_eggs && (
                <div className="charm">
                  <OptionTypeIcon type="premium" size={18} position="bottom" />
                </div>
              )}
              {donorData.is_baby_bundle && (
                <div className="charm">
                  <OptionTypeIcon type="baby-bundle" size={18} position="bottom" />
                </div>
              )}
              {donorData.is_family_bundle && (
                <div className="charm">
                  <OptionTypeIcon type="family-bundle" size={18} position="bottom" />
                </div>
              )}
              {donorData.is_banked_custom_choice && (
                <div className="charm">
                  <OptionTypeIcon type="banked-custom-choice" size={18} position="bottom" />
                </div>
              )}              
            </div>
          </div>
          <Button
            hasIcon
            disabled={isGeneratingPdf}
            onClick={async () => {
              setGeneratingPdf(true);
              const blob = await pdf(<DonorProfilePdf donor={donor} locale={locale} />).toBlob();
              const url = URL.createObjectURL(blob);
              const a = document.createElement('a');
              document.body.appendChild(a);
              a.style = 'display: none';
              a.href = url;
              a.target = '_blank';
              a.click();
              setGeneratingPdf(false);
            }}
            style={{ marginLeft: 'auto' }}
          >
            {isGeneratingPdf && <LoadingWhite />}
            {!isGeneratingPdf && <Print />}
            Print
          </Button>
        </div>
        <div className="donor-profile__navigation-tabs">
          <nav>
            <ul style={{ display: 'flex' }}>
              <li className={cn({ active: currentTab === 'overview' })} onClick={() => setCurrentTab('overview')}>
                <DonorView size={20} fill={currentTab === 'overview' ? '#FFFFFF' : colors.darkGray} />
                Overview
              </li>
              <li
                className={cn({ active: currentTab === 'medical-history' })}
                onClick={() => setCurrentTab('medical-history')}
              >
                <MedicalView size={20} fill={currentTab === 'medical-history' ? '#FFFFFF' : colors.darkGray} />
                Medical History
              </li>
              <li
                className={cn({ active: currentTab === 'family-overview' })}
                onClick={() => setCurrentTab('family-overview')}
              >
                <FamilyView size={20} fill={currentTab === 'family-overview' ? '#FFFFFF' : colors.darkGray} />
                Family Overview
              </li>
              {donorData.genetic_screening_results?.length ? (
                <li
                  className={cn({ active: currentTab === 'genetic-report' })}
                  onClick={() => setCurrentTab('genetic-report')}
                >
                  <GeneticView size={20} fill={currentTab === 'genetic-report' ? '#FFFFFF' : colors.darkGray} />
                  Genetic Report
                </li>
              ) : null}
            </ul>
          </nav>
          <div className="donor-profile__content">
            {currentTab === 'overview' && <DonorProfileOverview donor={donor} />}
            {currentTab === 'medical-history' && (
              <DonorProfileMedicalHistory issues={donor.get('medical_issue_details', new List())} />
            )}
            {currentTab === 'family-overview' && (
              <DonorProfileFamily family={donor.get('family_members', new List())} />
            )}
            {currentTab === 'genetic-report' && (
              <DonorProfileGeneticReport donorId={donorData.donor_id} reports={donorData.genetic_screening_results} />
            )}
          </div>
          <div className="donor-profile__footer">
            <QuestionCircle fill={colors.brandPurple} />
            <h5>
              Questions or need help?{' '}
              <span className="anchor-link" onClick={() => setShowingContactUs(true)}>
                Contact Us
              </span>
              .
            </h5>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DonorProfileModal;
