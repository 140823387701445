import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { usePhotos } from '../../common/utils/hooks';
import { setLightboxPhotoId } from '../../redux/ui-actions';
import DonorFavorite from './DonorFavorite';
import Image from '../../common/components/icons/image';

const DonorProfilePhotos = ({ donor }) => {
  const dispatch = useDispatch();
  const {
    photos,
    primary: { index: primaryIndex },
  } = usePhotos(donor);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    setIndex(primaryIndex);
  }, [primaryIndex]);

  return (
    <div>
      <div className="donor-profile__profile-photo">
        <img
          src={photos.getIn([index, 'full'])}
          alt={`Donor ${donor.get('donor_id')}`}
          onClick={() => dispatch(setLightboxPhotoId(photos.getIn([index, 'id'])))}
        />
        <DonorFavorite donorId={donor.get('donor_id')} favorited={donor.get('favorited')} />
        <div className="button__view-gallery" onClick={() => dispatch(setLightboxPhotoId(photos.getIn([index, 'id'])))}>
          <Image fill="white" />
          View Gallery
        </div>
      </div>
      <div className="donor-profile__thumbnails">
        {photos
          .take(4)
          .map((photo, i) => (
            <img
              key={photo.get('id')}
              src={photo.get('thumb')}
              onError={({target}) => {
                target.onerror = null;
                target.src = photo.get('thumb').replace(/\.[^.]*$/, '.jpg');
              }}
              alt={`Donor ${donor.get('donor_id')}`}
              onClick={() => dispatch(setLightboxPhotoId(photo.get('id')))}
            />
          ))
          .toJS()}
      </div>
    </div>
  );
};

export default DonorProfilePhotos;
