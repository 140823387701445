import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';

import config from '../../config';
import Button from '../../common/components/buttons/button';

const ScheduleAppointment = ({ donorId }) => {
  const [firstName, lastName, email] = useSelector(
    ({ user }) => [
      user.getIn(['data', 'profile', 'firstName']),
      user.getIn(['data', 'profile', 'lastName']),
      user.getIn(['data', 'email']),
    ],
    shallowEqual
  );

  /*
    Calendly Event Invitee Questions:
    a1: Phone Number
    a2: Please share anything that will help prepare for our meeting.
   */
  const calendlyCustomAnswers = {
    a1: '',
    a2: donorId ? `I'd like to make an inquiry about Donor #${donorId}` : '',
  };

  return (
    <div>
      <h5 className="margin-bottom__xs">Chat with us on your schedule</h5>
      <p className="margin-bottom__m">
        If you are searching for your donor and need assistance, have questions, or are ready to reserve your donor,
        schedule a time to talk with one of our experienced, caring client representatives.
      </p>
      <div className="button-group">
        {config.calendly.eventTypes.map(eventType => (
          <Button
            buttonType="primary-alt"
            key={eventType.name}
            onClick={() => {
              window.Calendly.initPopupWidget({
                url: `${config.calendly.baseUrl}${eventType.link}`,
                prefill: {
                  email,
                  firstName,
                  lastName,
                  name: firstName && lastName ? `${firstName} ${lastName}` : null,
                  customAnswers: calendlyCustomAnswers,
                },
              });
            }}
          >
            {eventType.name}
          </Button>
        ))}
      </div>
    </div>
  );
};

export default ScheduleAppointment;
