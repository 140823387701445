import React, { useState, useEffect, useRef } from 'react';
import { Form, Formik, Field, ErrorMessage } from 'formik';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

import Input from '../../common/forms/Input';
import { getValidationErrors } from '../../common/utils/form-helpers';

import { registerUser } from '../../redux/user-actions';
import { spacing, typography, colors } from '../../app-theme';
import Button from '../../common/components/buttons/button';

import helpImage from '../../common/images/Help-02-32.png';
import { getCountriesList, getStatesList } from '../../common/utils/countries-and-states'

const SCHEMA = Yup.object().shape({
  firstName: Yup.string()
    .trim()
    .max(64, 'Name must be 64 characters or less')
    .required('Please enter your first name'),
  lastName: Yup.string()
    .trim()
    .max(64, 'Name must be 64 characters or less')
    .required('Please enter your last name'),
  email: Yup.string()
    .trim()
    .email()
    .required('Please enter your email'),
  password: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .required('Please enter a password'),

  ///
  /// Validate that the user selected an option for contact permission
  ///
  mayWeContactYou: Yup.string().required('Please tell us if we may contact you'),

  ///
  /// Validate that the user provided a contact telephone # - but only if they agreed to contact
  ///
  contactPhoneNumber: Yup.string()
    .when("mayWeContactYou", {
      is: "True",
      then: Yup.string().required('Please enter your contact telephone #'),
      otherwise: Yup.string()
    }),

  ///
  /// Validate that the user selected a country of residence
  ///
  country: Yup.string().required('Please select a country'),

  ///
  /// Validate that the user provided a state of residence - but only if they have selected one of the countries where we track states. 
  ///
  state: Yup.string()
    .when('country', (country, schema) => {
      if (country === 'US' || country === 'AU' || country === 'CA') return schema.required('Please select a state/territory');
    }),

  ///
  /// Validate that the user provided a province/country or region - but only if they have selected one of the countries where we do NOT track states. 
  ///
  province: Yup.string()
    .when('country', (country, schema) => {
      if (country !== 'US' && country !== 'AU' && country !== 'CA') return schema.required('Please enter a province/county or region');
    })

});

export default ({ onSuccess }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showContactPhoneNumber, setShowContactPhoneNumber] = useState(false);
  const [showState, setShowState] = useState(false);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);

  const dispatch = useDispatch();
  const formikRef = useRef();
  ///
  /// Fetch list of contries (and generate country Select options) when the page is loaded
  ///
  useEffect(() => {
    async function getCountries() {
      try {
        const countriesData = await getCountriesList();
        const countriesHtml = countriesData.map((countriesData) => {
          return (
            <option key={countriesData.CountryCode} value={countriesData.CountryCode}>
              {countriesData.Name}
            </option>
          );
        })
        setCountries(countriesHtml);
      } catch (error) {
        console.log(error);
      }
    }
    getCountries();

  }, []);

  ///
  /// Reveal/hide the contact telephone # based on whether or not the recipient agrees to be contacted
  ///
  const showHideContactNumber = (e) => {
    setShowContactPhoneNumber(e.target.value);
  };

  ///
  /// When the country of residence is changed, refresh the list of possible states.
  ///
  const countryChanged = (e) => {
    getStates(e.target.value);
  }

  ///
  /// Fetch a list of states/provinces/territories for the selected country, generate the State selection options, and reveal/hide the stateal drop down
  ///
  async function getStates(countryCode) {
    let statesData = await getStatesList(countryCode);
    let hasStates = (statesData !== null && statesData.length > 0);
    let statesHtml = [];

    if (hasStates) {
      statesHtml = statesData.map((statesData) => {
        return (
          <option key={statesData.StateCode} value={statesData.StateCode}>
            {statesData.Name}
          </option>
        );
      });
    }

    setStates(statesHtml);
    setShowState(hasStates);
  }

  return (
    <Formik
      innerRef={formikRef}
      initialValues={{
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        mayWeContactYou: '',
        contactPhoneNumber: '',
        country: '',
        state: '',
        province: ''
      }}
      onSubmit={async (values, { setErrors }) => {
        const { response, json } = await dispatch(registerUser(values));
        if (response.ok && response.status !== 204) {
          onSuccess();
        } else if (response.status === 422) {
          setErrors(getValidationErrors(json.validationErrors));
        } else {
          setErrors({ password: 'Sorry, we are unable to create your account at this time, please try again later.' });
        }
      }
      }
      validationSchema={SCHEMA}
    >
      {({ isSubmitting }) => (
        <Form>
          <Input
            id="firstName"
            name="firstName"
            label="First Name"
            placeholder="Jane"
            disabled={isSubmitting}
            rounded
          />
          <Input id="lastName" name="lastName" label="Last Name" placeholder="Doe" disabled={isSubmitting} rounded />
          <Input
            id="email"
            name="email"
            label="Email"
            type="email"
            placeholder="jane.doe@example.com"
            disabled={isSubmitting}
            rounded
          />
          <div className='input-scaffold' >
            <label htmlFor="mayWeContactYou" className='input-label'>
              May we call/text you?
            </label>
            <Field name="mayWeContactYou" as="select" className="input-rounded" onChange={e => { formikRef.current.setFieldValue('mayWeContactYou', e.target.value); showHideContactNumber(e)}} >
              <option value="">Please select an option ...</option>
              <option value="True">Yes</option>
              <option value="False">No</option>
            </Field>
          </div>
          <ErrorMessage name="mayWeContactYou" render={msg => <div style={{ color: 'red' }} >{msg}</div>} />
          <br />
          {showContactPhoneNumber === 'True' &&
            <Input
              id="contactPhoneNumber"
              name="contactPhoneNumber"
              label="Contact Phone #"
              placeholder="(555) 555 5555"
              disabled={isSubmitting}
              rounded
            />
          }
          <div className='input-scaffold' >
            <span>
              <label htmlFor="country" className='input-label'>
                Country Where You Reside &nbsp;&nbsp;&nbsp;
                <span title='This information allows us to provide you with donors who meet requirements in your area.' ><img src={helpImage} width={16} height={16} alt='' /></span>
              </label>
            </span>
            <Field name="country" as="select" className="input-rounded" onChange={e => { formikRef.current.setFieldValue('country', e.target.value); countryChanged(e)}} >
              <option value=''>Please select a country</option>
              {countries}
            </Field>
            <ErrorMessage name="country" render={msg => <div style={{ color: 'red' }} >{msg}</div>} />
          </div>
          <br />
          <div className='input-scaffold'>
            {showState &&
              <label htmlFor="state" className='input-label'>
                State/province/territory
              </label>
            }
            {showState &&
              <Field name="state" as="select" className="input-rounded">
                <option value=''>Please select a state/province/territory</option>
                {states}
              </Field>
            }
            {showState &&
              <ErrorMessage name="state" render={msg => <div style={{ color: 'red' }} >{msg}</div>} />
            }
          </div>

          {!showState &&
            <Input id="province" name="province" label="Province/county/region" placeholder="Enter province/county or region" disabled={isSubmitting} rounded />
          }

          <Input
            disabled={isSubmitting}
            id="password"
            name="password"
            label="Password"
            placeholder="Shhh. It's a secret!"
            type={showPassword ? 'text' : 'password'}
            rounded
          >
            <button type="button" onClick={() => setShowPassword(!showPassword)} style={styles.showHideButton}>
              {showPassword ? 'hide' : 'show'}
            </button>
          </Input>
          <div style={styles.termsConfirmation}>
            <p>
              By creating an account, you have read and agree to our{' '}
              <a
                href="https://www.theworldeggandspermbank.com/privacy-policy/"
                rel="noopener noreferrer"
                target="_blank"
              >
                terms and conditions
              </a>
              .
            </p>
          </div>
          <Button type="submit" buttonType="primary" disabled={isSubmitting}>
            Create Account
          </Button>
        </Form>
      )}
    </Formik>
  );
};

const styles = {
  help: {
    background: 'red'
  },
  termsConfirmation: {
    marginTop: spacing.u3,
    marginBottom: spacing.u4,
  },
  showHideButton: {
    position: 'absolute',
    top: spacing.u5,
    right: spacing.u3,
    background: 'none',
    border: 'none',
    fontSize: '12px',
    textTransform: 'uppercase',
    fontWeight: typography.bold,
    color: colors.brandPurple,
    cursor: 'pointer',
    outline: 0,
  },
};
