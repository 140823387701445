import React from 'react';
import client from '../../client';
import config from '../../config';
import TwebLogoEgg from '../../common/components/logo/logo-colored-egg';
import Button from '../../common/components/buttons/button';

const DonorProfileGeneticReport = ({ donorId, reports }) => {
  return (
    <div>
      <div className="center margin-bottom__l">
        <div className="margin-top__m margin-bottom__s">
          <TwebLogoEgg />
        </div>
        <p className="paragraph__large" style={{ maxWidth: '80%' }}>
          View this donor's genetic screening results covering more than <strong>10,000 genetic traits.</strong>
        </p>
      </div>
      <p className="margin-bottom__s">
        <strong>IMPORTANT:</strong> Even though testing results may be negative, this should not be interpreted that the
        tissue is free from any genetic condition or that this donor is an appropriate match for the sperm contributor.
        Genetic counseling is recommended and available through Genescreen or your physician. Original reports are
        available to your physician or genetic counselor. These results must be reviewed by your physician.
      </p>
      <p className="margin-bottom__m">
        This donor was tested for a panel of diseases using a combination of sequencing, targeted genotyping and copy
        number analysis. Please note that negative results reduce but do not eliminate the possibility that this
        individual is a carrier for one or more of the disorders tested. For specific detection rates and residual risk
        by ethnicity, please go to{' '}
        <a target="_blank" rel="noopener noreferrer" href="http://go.sema4.com/residualrisk">
          http://go.sema4.com/residualrisk
        </a>
        . With individuals of mixed ethnicity, it is recommended to use the highest residual risk estimate. Only
        variants determined to be pathogenic or likely pathogenic are reported in this carrier screening test.
      </p>
      <div className="center margin-bottom__m">
        {reports.map((report, i) => (
          <Button
            key={report.document_id}
            buttonType="primary"
            className="margin-bottom__s"
            onClick={async () => {
              const res = await client.get(`donors/${donorId}/document/${report.document_id}/download-token`);
              const { token } = await res.json();

              const reportWindow = window.open();
              reportWindow.opener = null;
              reportWindow.location = `${config.apiUrl}/donors/${donorId}/document/${report.document_id}?token=${token}`;
            }}
          >
            {reports.length > 1 ? `Genetic Report ${i + 1}` : 'View & Download Report'}
          </Button>
        ))}
      </div>
    </div>
  );
};

export default DonorProfileGeneticReport;
