import merge from './common/utils/merge';

const MAX_LEN = 64 - 10;
const getQaUrl = container => {
  let qaUrlBase = `${process.env.REACT_APP_CS_PROJECT}-${process.env.REACT_APP_CS_REPO}`;
  let oidcBase = `${qaUrlBase}-${container}-${process.env.REACT_APP_CS_BRANCH}`;
  oidcBase = oidcBase.toLowerCase().substr(0, MAX_LEN);
  return oidcBase + '.synsit.es';
};

const defaults = {
  validationMessages: {},
  apiUrl: process.env.REACT_APP_API_URL || 'https://api.dev.theworldeggbank.com',
  auth: {
    clientId: process.env.REACT_APP_OIDC_CLIENT_ID,
    domain: process.env.REACT_APP_OIDC_BASE_URL,
    redirectUri: process.env.REACT_APP_URL,
    postLogoutRedirectUri: process.env.REACT_APP_URL,
    scope: 'openid',
    responseType: 'code id_token token',
    idTokenKey: 'idToken',
  },
  calendly: {
    baseUrl: 'https://calendly.com/synapse-dev',
    eventTypes: [
      {
        link: '/',
        name: 'Schedule a call',
      },
    ],
  },
  cdnUrl: 'https://tweb-dev.s3-us-west-2.amazonaws.com',
  // Miles test ENV S3Bucket
  // cdnUrl: 'https://s3.us-west-2.amazonaws.com/miles.test.theworldeggbank.com',
};

const environments = {
  development: {},
  qa: {
    apiUrl: `https://${getQaUrl('api')}`,
  },
  staging: {
    apiUrl: process.env.REACT_APP_API_URL || 'https://api.staging.theworldeggbank.com',
    cdnUrl: 'https://cdn.staging.theworldeggbank.com',
    calendly: {
      baseUrl: 'https://calendly.com/recipient-team',
    },
  },
  production: {
    apiUrl: process.env.REACT_APP_API_URL || 'https://api.theworldeggbank.com',
    calendly: {
      baseUrl: 'https://calendly.com/recipient-team',
    },
    cdnUrl: 'https://cdn.theworldeggbank.com',
  },
};

export default merge(defaults, environments[process.env.REACT_APP_ENV]);
