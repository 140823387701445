import React, { useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import store from 'store2';

import Button from '../../common/components/buttons/button';
import Loading from '../../common/components/Loading';
import RightArrow from '../../common/components/icons/right-arrow';
import TwebLogoWhiteHorizontal from '../../common/components/logo/logo-horizontal-white';
import { usePhotos, useQuery } from '../../common/utils/hooks';

import { getDonorProfile } from '../../redux/donor-actions';

const LoginModal = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [query] = useQuery();

  const [donor, loaded, loading] = useSelector(
    ({ donors: { profile } }) => [profile.get('data'), profile.get('loaded'), profile.get('loading')],
    shallowEqual
  );

  useEffect(() => {
    if (query.get('profileId')) {
      dispatch(getDonorProfile(query.get('profileId')));
    }
  }, [dispatch, query]);

  const {
    primary: { full, largePlaceholder },
  } = usePhotos(donor);

  if (!loaded || loading || !query.get('profileId')) {
    return <Loading />;
  }

  return (
    <div className="login-modal__wrapper">
      <div className="login-modal__donor-container">
        <div className="login-modal__donor-image">
          <div className="donor-card" style={{ backgroundImage: `url(${full}), url(${largePlaceholder})` }} />
          <div className="login-modal__donor-id">ID: {query.get('profileId')}</div>
        </div>
        <div>
          <TwebLogoWhiteHorizontal />
        </div>
      </div>
      <div className="login-modal__content">
        <h2 className="brandPurple margin-bottom__s">One step away from finding your perfect donor.</h2>
        <p className="margin-bottom__m">
          In order to see more information about donors and save them to your personalized list, you need to sign in or
          register for an account.
        </p>
        <p className="margin-bottom__l">
          Don't have an account? <Link to="/register">Create an account</Link>
        </p>
        <Button
          buttonType="primary"
          hasIcon
          iconRight
          onClick={() => {
            store.set('loginPath', location.pathname);
            store.set('loginSearch', location.search);
            history.push('/login');
          }}
          className="margin-bottom__s"
        >
          Log In <RightArrow fill="white" />
        </Button>
      </div>
    </div>
  );
};

export default LoginModal;
