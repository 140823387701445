import Client, { JwtPlugin } from '@synapsestudios/fetch-client';
import localstorage from 'store2';
import config from './config';

const client = new Client({ url: config.apiUrl });

client.addPlugin(new JwtPlugin());
client.setJwtTokenGetter(() => localstorage.get('idToken'));

export default client;
