import React, { Fragment, useMemo } from 'react';
import cn from 'classnames';

import DonorFavorite from './DonorFavorite';
import Search from '../../common/components/icons/search';
import { usePhotos, useQuery } from '../../common/utils/hooks';
import OptionTypeIcon from '../../common/components/OptionTypeIcon';

import { colors } from '../../app-theme';

const DonorCard = ({ donor }) => {
  const donorData = useMemo(() => donor.toJS(), [donor]);

  const [query, setQuery] = useQuery();

  const {
    primary: { full, largePlaceholder },
  } = usePhotos(donor);

  const photoAlt = full.replace(/\.[^.]*$/, '.jpg');

  return (
    <div
      className={cn('donor-card', { 'donor-card--inactive': !donorData.active })}
      style={{ backgroundImage: `url(${full}), url(${photoAlt}), url(${largePlaceholder})` }}
      onClick={() => setQuery(query.set('profileId', donorData.donor_id))}
    >
      <div className="donor-card__container">
        <div className="donor-card__top">
          {donorData.active && (
            <Fragment>
              {donorData.coming_soon && <span className="donor-status coming-soon">coming soon</span>}
              <DonorFavorite donorId={donorData.donor_id} favorited={donorData.favorited} />
            </Fragment>
          )}
          {!donorData.active && <span className="donor-status inactive">inactive</span>}
        </div>

        <div className="donor-card__donor-details">
          {donorData.ethnicity && (
            <div className="donor-card__donor-details__row">
              <div className="donor-card__donor-details__detail">
                <div className="value">{donorData.ethnicity}</div>
                <div className="label">race</div>
              </div>
            </div>
          )}

          <div className="donor-card__donor-details__row">
            <div className="donor-card__donor-details__detail">
              <div className="value">{donorData.hair_color}</div>
              <div className="label">hair color</div>
            </div>

            {donorData.eye_color && (
              <div className="donor-card__donor-details__detail">
                <div className="value">{donorData.eye_color}</div>
                <div className="label">eye color</div>
              </div>
            )}
          </div>

          <div className="donor-card__donor-details__row">
            <div className="donor-card__donor-details__detail">
              <div className="value">{donorData.height}</div>
              <div className="label">height</div>
            </div>
          </div>

          <div className="donor-card__donor-details__row">
            <div className="donor-card__donor-details__detail">
              <div className="value">{donorData.blood_type}</div>
              <div className="label">blood type</div>
            </div>
            <div className="donor-card__donor-details__detail">
              <div className="value">{donorData.blood_type_rh}</div>
              <div className="label">rh factor</div>
            </div>
          </div>

          {donorData.education_level_public && (
            <div className="donor-card__donor-details__row">
              <div className="donor-card__donor-details__detail">
                <div className="value">{donorData.education_level_public}</div>
                <div className="label">education level</div>
              </div>
            </div>
          )}
        </div>

        <div className="donor-card__bottom">
          {donorData.banked_eggs && (
            <div className="donor-card__charm">
              <OptionTypeIcon type="premium" size={18} position="top" />
            </div>
          )}
          {donorData.is_baby_bundle && (
            <div className="donor-card__charm">
              <OptionTypeIcon type="baby-bundle" size={18} position="top" />
            </div>
          )}
          {donorData.is_family_bundle && (
            <div className="donor-card__charm">
              <OptionTypeIcon type="family-bundle" size={18} position="top" />
            </div>
          )}
          {donorData.is_banked_custom_choice && (
            <div className="donor-card__charm">
              <OptionTypeIcon type="banked-custom-choice" size={20} position="top" />
            </div>
          )}
          {donorData.active && (
            <div className="donor-card__view-button button button--has-icon button--size--small button--white">
              <Search size={20} fill={colors.brandPurple} />
              view
            </div>
          )}
        </div>

        <div className="donor-card__donor-id">
          <span>ID: {donorData.donor_id}</span>
        </div>
      </div>
    </div>
  );
};

export default DonorCard;
