import { combineReducers } from 'redux';
import { fromJS } from 'immutable';
import { EDIT_FAVORITE_REQUEST, EDIT_FAVORITE_SUCCESS, EDIT_FAVORITE_RESET } from './donor-actions';

const {
  DELETE_FAVORITE_SUCCESS,
  GET_DONOR_PROFILE_REQUEST,
  GET_DONOR_PROFILE_SUCCESS,
  GET_FAVORITES_REQUEST,
  GET_FAVORITES_SUCCESS,
  POST_FAVORITE_SUCCESS,
  SEARCH_DONORS_REQUEST,
  SEARCH_DONORS_SUCCESS,
} = require('./donor-actions');

const list = (state = fromJS({ data: [], loaded: false, loading: false, total: 0 }), action) => {
  if (
    [GET_FAVORITES_REQUEST, SEARCH_DONORS_REQUEST].includes(action.type) &&
    Number.isNaN(Number.parseInt(action.from, 10))
  ) {
    // Reset the donor list
    return fromJS({
      from: 0,
      data: [],
      loaded: false,
      loading: true,
      total: 0,
    });
  } else if ([GET_FAVORITES_REQUEST, SEARCH_DONORS_REQUEST].includes(action.type)) {
    return state.set('loading', true);
  } else if ([GET_FAVORITES_SUCCESS, SEARCH_DONORS_SUCCESS].includes(action.type)) {
    // Add to the donor list
    let data = state.get('data');

    if (action.from < state.get('from')) {
      // Prepend to list
      data = fromJS(action.json.hits).concat(data);
    } else {
      // Append to List
      data = data.concat(fromJS(action.json.hits));
    }

    return state
      .merge({
        from: Number.parseInt(action.from, 10),
        firstPage: Math.min(state.get('firstPage', action.from || 0), action.from || 0),
        lastPage: Math.max(state.get('firstPage', action.from || 0), action.from || 0),
        loaded: true,
        loading: false,
        total: action.json.total,
      })
      .set('data', data);
  } else if (action.type === POST_FAVORITE_SUCCESS) {
    let data = state.get('data');

    const [index, donor] = data.findEntry(donor => donor.get('donor_id') === action.donorId, null, [-1, null]);

    if (donor) {
      data = data.set(index, donor.set('favorited', fromJS(true)));
    }

    return state.set('data', data);
  } else if (action.type === DELETE_FAVORITE_SUCCESS) {
    let data = state.get('data');

    const [index, donor] = data.findEntry(donor => donor.get('donor_id') === action.donorId, null, [-1, null]);

    if (donor) {
      data = data.set(index, donor.set('favorited', fromJS(false)));
    }

    return state.set('data', data);
  }

  return state;
};

const profile = (
  state = fromJS({ lightboxPhotoId: null, profileId: null, data: {}, loaded: false, loading: false }),
  action
) => {
  if (action.type === GET_DONOR_PROFILE_REQUEST) {
    return state.merge({ loaded: false, loading: true }).set('data', fromJS({}));
  } else if (action.type === GET_DONOR_PROFILE_SUCCESS) {
    return state.merge({ loaded: true, loading: false }).set('data', fromJS(action.json));
  } else if (action.type === POST_FAVORITE_SUCCESS || action.type === EDIT_FAVORITE_SUCCESS) {
    return state.setIn(['data', 'favorited'], fromJS(action.json));
  } else if (action.type === DELETE_FAVORITE_SUCCESS) {
    return state.setIn(['data', 'favorited'], fromJS(null));
  }

  return state;
};

const editFavoriteApiRequest = (state = fromJS({ saving: false, saved: false }), action) => {
  if (action.type === EDIT_FAVORITE_REQUEST) {
    return state.merge({
      saving: true,
      saved: false,
    });
  } else if (action.type === EDIT_FAVORITE_SUCCESS) {
    return state.merge({
      saving: false,
      saved: true,
    });
  } else if (action.type === EDIT_FAVORITE_RESET) {
    return state.merge({
      saving: false,
      saved: false,
    });
  }
  return state;
};

export default combineReducers({ list, profile, editFavoriteApiRequest });
