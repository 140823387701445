import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';

import InputScaffold from './InputScaffold';

const Input = forwardRef(({ children, className, disabled, label, placeholder, rounded, type, ...props }, ref) => {
  const [field, meta] = useField({ disabled, ...props });

  return (
    <InputScaffold
      className={className}
      disabled={disabled}
      error={meta.error}
      id={field.id}
      label={label}
      touched={meta.touched}
    >
      <input
        className={rounded && 'input-rounded'}
        type={type}
        disabled={disabled}
        {...field}
        {...props}
        placeholder={placeholder}
        ref={ref}
      />
      {children}
    </InputScaffold>
  );
});

Input.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  type: PropTypes.oneOf([
    'button',
    'checkbox',
    'color',
    'date',
    'datetime-local',
    'email',
    'file',
    'hidden',
    'image',
    'month',
    'number',
    'password',
    'radio',
    'range',
    'reset',
    'search',
    'submit',
    'tel',
    'text',
    'time',
    'url',
    'week',
  ]),
};

Input.defaultProps = {
  children: null,
  className: null,
  disabled: false,
  label: null,
  type: 'text',
};

export default Input;
