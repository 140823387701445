import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { editFavorite, editFavoriteReset } from '../../redux/donor-actions';
import TextButton from '../../common/components/buttons/text-button';

const DonorProfileNotes = ({ favorite }) => {
  const [notes, setNotes] = useState(favorite.notes);
  const dispatch = useDispatch();

  useEffect(() => {
    return () => dispatch(editFavoriteReset());
  }, [dispatch]);

  const [saving, saved] = useSelector(
    ({ donors: { editFavoriteApiRequest } }) => [
      editFavoriteApiRequest.get('saving'),
      editFavoriteApiRequest.get('saved'),
    ],
    shallowEqual
  );

  let saveButtonText = 'Save';
  if (saving) {
    saveButtonText = 'Saving...';
  } else if (saved) {
    saveButtonText = 'Saved!';
  }

  return (
    <div className="donor-profile__notes">
      <h5 className="margin-bottom__xs">Notes</h5>
      <textarea
        className="donor-profile__textarea"
        disabled={saving}
        placeholder="1000 characters or less"
        value={notes || ''}
        onChange={e => {
          if (saved) {
            dispatch(editFavoriteReset());
          }
          setNotes(e.currentTarget.value.slice(0, 1000));
        }}
      />
      <TextButton
        onClick={() => {
          dispatch(editFavorite(favorite.id, notes));
        }}
        disabled={saving}
        className="donor-profile__text-button"
      >
        {saveButtonText}
      </TextButton>
    </div>
  );
};

export default DonorProfileNotes;
