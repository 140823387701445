import { isNull, isUndefined } from 'lodash';

import client from '../client';

export const DELETE_FAVORITE = 'DELETE_FAVORITE';
export const DELETE_FAVORITE_REQUEST = 'DELETE_FAVORITE_REQUEST';
export const DELETE_FAVORITE_SUCCESS = 'DELETE_FAVORITE_SUCCESS';
export const DELETE_FAVORITE_FAILURE = 'DELETE_FAVORITE_FAILURE';

export const GET_DONOR_PROFILE = 'GET_DONOR_PROFILE';
export const GET_DONOR_PROFILE_REQUEST = 'GET_DONOR_PROFILE_REQUEST';
export const GET_DONOR_PROFILE_SUCCESS = 'GET_DONOR_PROFILE_SUCCESS';
export const GET_DONOR_PROFILE_FAILURE = 'GET_DONOR_PROFILE_FAILURE';

export const GET_FAVORITES = 'GET_FAVORITES';
export const GET_FAVORITES_REQUEST = 'GET_FAVORITES_REQUEST';
export const GET_FAVORITES_SUCCESS = 'GET_FAVORITES_SUCCESS';
export const GET_FAVORITES_FAILURE = 'GET_FAVORITES_FAILURE';

export const POST_FAVORITE = 'POST_FAVORITE';
export const POST_FAVORITE_REQUEST = 'POST_FAVORITE_REQUEST';
export const POST_FAVORITE_SUCCESS = 'POST_FAVORITE_SUCCESS';
export const POST_FAVORITE_FAILURE = 'POST_FAVORITE_FAILURE';

export const EDIT_FAVORITE = 'EDIT_FAVORITE';
export const EDIT_FAVORITE_REQUEST = 'EDIT_FAVORITE_REQUEST';
export const EDIT_FAVORITE_SUCCESS = 'EDIT_FAVORITE_SUCCESS';
export const EDIT_FAVORITE_RESET = 'EDIT_FAVORITE_RESET';

export const SEARCH_DONORS = 'SEARCH_DONORS';
export const SEARCH_DONORS_REQUEST = 'SEARCH_DONORS_REQUEST';
export const SEARCH_DONORS_SUCCESS = 'SEARCH_DONORS_SUCCESS';
export const SEARCH_DONORS_FAILURE = 'SEARCH_DONORS_FAILURE';
export const SET_DONOR_PROFILE_ID = 'SET_DONOR_PROFILE_ID';
export const SET_LIGHTBOX_PHOTO_ID = 'SET_LIGHTBOX_PHOTO_ID';

const VALID_QUERY = {
  FAVORITES: ['from', 'option'],
  SEARCH: [
    'ancestry',
    'available_in',
    'blood_type',
    'blood_type_rh',
    'eye_color',
    'from',
    'hair_color',
    'hair_texture',
    'height',
    'id',
    'min_height',
    'max_height',
    'option',
    'option_type',
    'race',
  ],
};

export const deleteFavorite = donorId => ({
  type: DELETE_FAVORITE,
  promise: client.delete(`favorites/${donorId}`),
  donorId,
});

export const getDonorProfile = id => ({
  type: GET_DONOR_PROFILE,
  promise: client.get(`donors/${id}`),
});

export const getFavorites = query => {
  const params = query
    .filter((value, key) => VALID_QUERY.FAVORITES.includes(key) && !isNull(value) && !isUndefined(value))
    .toJS();

  return {
    type: GET_FAVORITES,
    promise: client.get('donors/favorites', params),
    from: query.get('from'),
  };
};

export const postFavorite = donorId => ({
  type: POST_FAVORITE,
  promise: client.post(`favorites`, { donorId }),
  donorId,
});

export const editFavorite = (favoriteId, notes) => ({
  type: EDIT_FAVORITE,
  promise: client.patch(`favorites/${favoriteId}`, { notes }),
  favoriteId,
});

export const editFavoriteReset = () => ({
  type: EDIT_FAVORITE_RESET,
});

export const searchDonors = query => {
  const params = query
    .filter((value, key) => VALID_QUERY.SEARCH.includes(key) && !isNull(value) && !isUndefined(value))
    .toJS();

  return {
    type: SEARCH_DONORS,
    promise: client.get('donors/search', params),
    from: query.get('from'),
  };
};
