import React from 'react';
import Svg, { G, Path, Defs, Mask, Use } from 'svgs';

const BabyBundle = ({ size, fill }) => {
  return (
    <Svg width={size || 24} viewBox="0 0 64 51">
      <Defs>
        <Path id="prefix__a" d="M0 .092h64V22.6H0z" />
        <Path id="prefix__c" d="M.8.2h54.8V23H.8z" />
        <Path id="prefix__e" d="M.4.3h18.1v14.4H.4z" />
      </Defs>
      <G fill="none" fillRule="evenodd">
        <G transform="translate(0 2.7)">
          <Mask id="prefix__b" fill="#fff">
            <Use xlinkHref="#prefix__a" />
          </Mask>
          <Path
            d="M59.8 14.2H28.4L44.2 4.1c1-.6 1.4-2 .7-3s-2-1.3-3-.7L20.2 14.2h-16C1.9 14.2 0 16 0 18.4c0 2.3 1.9 4.2 4.2 4.2h55.6c2.3 0 4.2-1.9 4.2-4.2 0-2.3-1.9-4.2-4.2-4.2"
            fill={fill || '#000'}
            mask="url(#prefix__b)"
          />
        </G>
        <G transform="translate(4 27.7)">
          <Mask id="prefix__d" fill="#fff">
            <Use xlinkHref="#prefix__c" />
          </Mask>
          <Path
            d="M.8 3.7C.8 14.4 9.4 23 20.1 23h16.2C47 23 55.6 14.4 55.6 3.7V.2H.8v3.5z"
            fill={fill || '#000'}
            mask="url(#prefix__d)"
          />
        </G>
        <G transform="translate(4 -.3)">
          <Mask id="prefix__f" fill="#fff">
            <Use xlinkHref="#prefix__e" />
          </Mask>
          <Path d="M18.5.3C9.8.5 2.5 6.6.4 14.7h18.1V.3z" fill={fill || '#000'} mask="url(#prefix__f)" />
        </G>
      </G>
    </Svg>
  );
};

export default BabyBundle;
