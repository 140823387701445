import React from 'react';
import BCC from './banked-custom-choice.png'
const BankedCustomChoice = ({ size, fill }) => {
  return (

    <img src={BCC} alt='Frozen Custom Choice' width={size*2}></img>
  );
};

export default BankedCustomChoice;
