import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { fromJS, List, Map } from 'immutable';

import { newQuery } from './query-helpers';
import config from '../../config';

// Taken from https://usehooks.com/useEventListener/
export const useEventListener = (eventName, handler, element = window) => {
  const savedHandler = useRef();

  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    const isSupported = element && element.addEventListener;
    if (!isSupported) return;

    const eventListener = event => savedHandler.current(event);

    element.addEventListener(eventName, eventListener);

    return () => {
      element.removeEventListener(eventName, eventListener);
    };
  }, [eventName, element]);
};

export const usePhotos = donor => {
  return useMemo(() => {
    const primary = donor.get('primary_photo');
    const largeThumb = donor.get('primary_photo_large_thumb');
    const thumb = donor.get('primary_photo_thumb');
    let photos = donor
      .get('photos', new List())
      .sortBy(photo => photo.get('sequence'))
      .map(
        photo =>
          new Map({
            full: `${config.cdnUrl}/${photo.get('key')}`,
            id: photo.get('photo_id'),
            primary: photo.get('primary'),
            thumb: `${config.cdnUrl}/${photo.get('thumb_key')}`,
            largeThumb: `${config.cdnUrl}/${photo.get('large_thumb_key')}`,
          })
      );

    if (!photos.size) {
      photos = photos.push(
        new Map({
          full: `${config.cdnUrl}/static/images/placeholder-egg.png`,
          id: 'placeholder',
          primary: false,
          thumb: `${config.cdnUrl}/static/images/placeholder-egg-thumb.png`,
        })
      );
    }

    return {
      primary: {
        full: primary ? `${config.cdnUrl}/${primary}` : `${config.cdnUrl}/static/images/placeholder-egg.png`,
        large: largeThumb ? `${config.cdnUrl}/${largeThumb}` : `${config.cdnUrl}/static/images/placeholder-egg.png`,
        index: photos.findIndex(photo => photo.get('primary')),
        largePlaceholder: `${config.cdnUrl}/static/images/placeholder-egg.png`,
        smallPlaceholder: `${config.cdnUrl}/static/images/placeholder-egg-thumb.png`,
        thumb: thumb ? `${config.cdnUrl}/${thumb}` : `${config.cdnUrl}/static/images/placeholder-egg-thumb.png`,
      },
      photos,
    };
  }, [donor]);
};

export const useQuery = initialSearch => {
  const history = useHistory();
  const { search } = useLocation();

  const setQuery = useCallback(
    (query, options = {}, mode = 'push') => {
      const search = newQuery(query);

      history[mode]({ ...options, search: `?${search}` });
    },
    [history]
  );

  const searchString = search || initialSearch;

  return useMemo(() => {
    const query = new URLSearchParams(searchString);

    const values = {};

    for (const [key, value] of query.entries()) {
      if (values[key]) {
        if (Array.isArray(values[key])) {
          values[key].push(value);
        } else {
          values[key] = [values[key], value];
        }
      } else {
        values[key] = value;
      }
    }

    return [fromJS(values), setQuery];
  }, [searchString, setQuery]);
};
