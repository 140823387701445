import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import PremiumBanked from './icons/premium-banked';
import BabyBundle from './icons/baby-bundle';
import FamilyBundle from './icons/family-bundle';
import BankedCustomChoice from './icons/banked-custom-choice';

import { colors } from '../../app-theme';

const OPTION_TYPES = {
  premium: {
    icon: PremiumBanked,
    name: 'Premium',
    description:
      'These donors have already been screened, stimulated, and eggs retrieved and frozen. Select your eggs and we ship them directly to your clinic for warming, fertilization, and transfer.',
  },
  'baby-bundle': {
    icon: BabyBundle,
    name: 'Baby Bundle',
    description:
      'Create a custom cohort of 6 from lots of 1 - 5 eggs remaining from our premium donors at a reduced rate.',
  },
  'family-bundle': {
    icon: FamilyBundle,
    name: 'Fresh Custom Choice',
    description:
      'In this process, you select a Fresh Custom Choice™ egg donor and we begin a fresh cycle consisting of screening, stimulating, and retrieving her eggs. You will have your partner’s or donor sperm shipped directly to us for fertilization of fresh donor eggs and the resulting embryos will be frozen and shipped to your clinic.',
  },
  'banked-custom-choice': {
    icon: BankedCustomChoice,
    name: 'Frozen Custom Choice',
    description:
      'With the Frozen Custom Choice ™ Embryo Option, you select an egg donor that already has eggs frozen in the bank. We thaw and fertilize the eggs using donor sperm from The World Egg and Sperm Bank or using your partner’s sperm or the donor sperm you ship to us. Once the donor eggs are fertilized, the resulting embryos will be vitrified and shipped to your clinic to use in your treatment.',
  },
};

const OptionTypeIcon = ({ type, showName, size, position, fill = colors.brandPurple }) => {
const [isHovering, setHovering] = useState(false);
const Icon = OPTION_TYPES[type].icon;

  return (
    <div className="tooltip" onMouseEnter={() => setHovering(true)} onMouseLeave={() => setHovering(false)}>
      {isHovering ? (
        <div className={cn('tooltip__bubble', `position--${position}`)}>
          <div className="tooltip__title">
            <Icon fill={fill} size={20} />
            <span>{OPTION_TYPES[type].name}</span>
          </div>
          <div className="tooltip__content">{OPTION_TYPES[type].description}</div>
        </div>
      ) : null}
      <Icon fill={fill} size={size} />
      {showName ? <span>{OPTION_TYPES[type].name}</span> : null}
    </div>
  );
};

OptionTypeIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
  showName: PropTypes.bool,
  type: PropTypes.oneOf(['premium', 'baby-bundle', 'family-bundle','banked-custom-choice']).isRequired,
};
export default OptionTypeIcon;
