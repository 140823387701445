import React from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';

import { newQuery } from '../../common/utils/query-helpers';
import { useQuery } from '../../common/utils/hooks';
import GridView from '../../common/components/icons/grid-view';
import ListView from '../../common/components/icons/list-view';
import { colors } from '../../app-theme';

const DonorNav = () => {
  const location = useLocation();
  const [query] = useQuery();
  const { mode, option } = query.toJS();

  return (
    <nav className="tab-filters margin-bottom__xl">
      <ul>
        <li>
          <NavLink
            exact
            isActive={() => !option}
            to={{ ...location, search: `?${newQuery(query.delete('option').delete('from'))}` }}
          >
            All Donors
          </NavLink>
        </li>
        <li>
          <NavLink
            exact
            isActive={() => option === 'premium'}
            to={{ ...location, search: `?${newQuery(query.set('option', 'premium').delete('from'))}` }}
          >
            Premium
          </NavLink>
        </li>
        <li>
          <NavLink
            exact
            isActive={() => option === 'baby-bundle'}
            to={{ ...location, search: `?${newQuery(query.set('option', 'baby-bundle').delete('from'))}` }}
          >
            Baby Bundle
          </NavLink>
        </li>
        <li>
          <NavLink
            exact
            isActive={() => option === 'family-bundle'}
            to={{ ...location, search: `?${newQuery(query.set('option', 'family-bundle').delete('from'))}` }}
          >
            Fresh Custom Choice
          </NavLink>
        </li>
        <li>
          <NavLink
            exact
            isActive={() => option === 'banked-custom-choice'}
            to={{ ...location, search: `?${newQuery(query.set('option', 'banked-custom-choice').delete('from'))}` }}
          >
            Frozen Custom Choice
          </NavLink>
        </li>
        <li>
          <NavLink
            exact
            isActive={() => option === 'upcoming'}
            to={{ ...location, search: `?${newQuery(query.set('option', 'upcoming').delete('from'))}` }}
          >
            Coming Soon
          </NavLink>
        </li>
        <li className="view-toggles">
          <Link to={{ ...location, search: `?${newQuery(query.delete('mode'))}` }}>
            <GridView size={32} fill={mode ? colors.lightGray : colors.brandPurple} />
          </Link>
          <Link to={{ ...location, search: `?${newQuery(query.set('mode', 'list'))}` }}>
            <ListView size={32} fill={mode === 'list' ? colors.brandPurple : colors.lightGray} />
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default DonorNav;
