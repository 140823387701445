import React from 'react';
import Email from '../../common/components/icons/email';
import Phone from '../../common/components/icons/phone';
import { colors } from '../../app-theme';

const ContactInformation = ({ headerText }) => {
  return (
    <div>
      {headerText ? (
        <h4 className="margin-bottom__s">{headerText}</h4>
      ) : (
        <h4 className="margin-bottom__s">
          You don't need an appointment to talk to us.
          <br />
          Give us a call or send an email!
        </h4>
      )}
      <a className="contact-link" href="tel:18773312427">
        <Phone fill={colors.brandPurple} />
        +1.877.331.2427
      </a>
      <a className="contact-link" href="mailto:recipients@twesb.com">
        <Email fill={colors.brandPurple} />
        recipients@twesb.com
      </a>
      <h5 className="margin-top__m margin-bottom__xs">Hours of Operation</h5>
      <div className="layout__columns-2">
        <div className="layout__column">
          <div className="layout__columns">
            <div className="hours-info">
              <div>Monday – Friday</div>
              <div>7AM – 4PM</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactInformation;
