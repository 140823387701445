import { Component } from 'react';
import store from 'store2';
import uuid from 'uuid';
import { withRouter } from 'react-router-dom';
import { getLoginUrl } from '../utils/oidc-url-builders';

class Login extends Component {
  componentDidMount() {
    // Detect when user is navigating back to cached state in Mobile Safari and redirect
    // to the route that brought us here, to prevent rendering a blank screen
    window.onpageshow = event => {
      if (event.persisted) {
        this.props.history.goBack();
      }
    };

    if ('%REACT_APP_ENV%' === 'production') {
      var dataLayer = window.dataLayer = window.dataLayer || [];
      function gtag() {
        dataLayer.push(arguments);
      }
      gtag('config','AW-851385468');
      gtag('event', 'conversion', {'send_to': 'AW-851385468/-RGiCNf-ppQDEPy4_JUD'});
    }

    const nonce = uuid();
    store.set('nonce', nonce);

    document.location.replace(getLoginUrl());
  }

  render() {
    return null;
  }
}

export default withRouter(Login);
