import client from '../client';

export const GET_USER = 'GET_USER';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';

export const getUser = () => ({
  type: GET_USER,
  promise: client.get('user'),
});

export const registerUser = values => ({
  type: REGISTER_USER,
  promise: client.post('users', values),
});

