import React from 'react';
import Svg, { Circle, Rect } from 'svgs';

const ListView = ({ size, fill }) => {
  return (
    <Svg height={size || 24} width={size || 24} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <Circle fill={fill || '#652365'} cx="4" cy="7" r="1" />
      <Circle fill={fill || '#652365'} cx="4" cy="12" r="1" />
      <Circle fill={fill || '#652365'} cx="4" cy="17" r="1" />
      <Rect fill={fill || '#652365'} x="7" y="11" width="14" height="2" rx=".94" ry=".94" />
      <Rect fill={fill || '#652365'} x="7" y="16" width="14" height="2" rx=".94" ry=".94" />
      <Rect fill={fill || '#652365'} x="7" y="6" width="14" height="2" rx=".94" ry=".94" />
    </Svg>
  );
};

export default ListView;
