import React, { useState, useEffect } from 'react';
import { Modal } from 'synfrastructure';
import { useSelector, useDispatch } from 'react-redux';

import DonorProfileContactUs from './DonorProfileContactUs';
import { getRecipientById } from '../../redux/recipient-actions';

import Close from '../../common/components/icons/close';

const DonorListContactUsButton = () => {
  const dispatch = useDispatch();
  const [contactUsModalVisible, setContactUsModalVisible] = useState(false);
  const recipientId = useSelector(({ user }) => user.getIn(['data', 'legacy_user_id']));
  const recipient = useSelector(({ recipient }) => recipient);
  const [showCuratedForYouMessage, setShowCuratedForYouMessage] = useState(true);

  ///
  /// Find the current recipient (if there is one) - and show/hide the country selections accordingly
  ///
  useEffect(() => {
    if (recipientId) {
      ///
      /// We only want to execute this if we don't already have a recipient
      ///
      if (!recipient.Id) {
        dispatch(getRecipientById(recipientId));
      }
      ///
      /// Hide the country selections if the recipient has a country of residence, but show them otherwise
      ///
      setShowCuratedForYouMessage(['US', 'AU', 'CA','UK'].includes(recipient.CountryCode));
    }
    else {
      ///
      /// If there is no recipient Id then this is an anonymous search, so don't hide the country selections
      ///
      setShowCuratedForYouMessage(false);
    }
  }, [dispatch, recipientId, recipient]);

  return (
    <div
      className="donor-list--contact-us__wrapper"
      style={{ justifyContent: recipientId ? 'space-between' : 'flex-end' }}
    >
      <Modal
        className="donor-profile-modal"
        closeButton={
          <span role="img" aria-label="Close Modal">
            <Close size={40} />
          </span>
        }
        isVisible={contactUsModalVisible}
        onClose={() => setContactUsModalVisible(false)}
      >
        <DonorProfileContactUs onClose={() => setContactUsModalVisible(false)} isVisible={contactUsModalVisible} />
      </Modal>
      {recipientId && (
        <div className="recipient-id margin-top__s ">
          <strong>Your recipient ID: {recipientId}</strong>
          {showCuratedForYouMessage && <div className='recipient-curated-message'>The donors you are viewing are specifically available in your country and fully comply with your countries laws, regulations and policies.</div>}
        </div>
      )}
      <button className="button button--primary" onClick={() => setContactUsModalVisible(true)}>
        Have questions? Contact Us
      </button>
    </div>
  );
};

export default DonorListContactUsButton;
