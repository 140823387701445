import React, { Component } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import StyleGuidePage from './style-guide/StyleGuidePage';
import Login from './common/auth/Login';
import Logout from './common/auth/Logout';
import ErrorBoundary from './common/components/ErrorBoundary';
import ForceReloadPrompt from './common/components/ForceReloadPrompt';
import NotFound from './common/components/NotFound';
import LayoutRoute from './common/components/LayoutRoute';

import { getUser } from './redux/user-actions';

// Layouts
import Auth from './layouts/Auth';
import Main from './layouts/Main';

// Views
import Contact from './views/Contact';
import Deleted from './views/Deleted';
import Donors from './views/Donors';
import Favorites from './views/Favorites';
import Register from './views/Register';
import Unsubscribe from './views/Unsubscribe';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  static getDerivedStateFromProps = ({ user }) => ({
    deleted: user.getIn(['data', 'deleted']),
    loaded: user.get('loaded'),
    loggedIn: user.get('loggedIn'),
    user: user.get('data'),
  });

  componentDidMount() {
    const { getUser } = this.props;
    const { loaded, loggedIn } = this.state;



    if (!loaded && loggedIn) {
      getUser();
    }
  }

  render() {
    const { shouldUpdate } = this.props;
    const { deleted, loggedIn } = this.state;

    if (shouldUpdate) {
      return (
        <ErrorBoundary>
          <ForceReloadPrompt />
        </ErrorBoundary>
      );
    }

    if (loggedIn && deleted) {
      return (
        <ErrorBoundary>
          <Switch>
            <LayoutRoute exact path="/contact" component={Contact} layout={Main} />
            <Route exact path="/logout" component={Logout} layout={Auth} />
            <LayoutRoute path="*" component={Deleted} layout={Auth} />
          </Switch>
        </ErrorBoundary>
      );
    }

    return (
      <ErrorBoundary>
        <Switch>
          {loggedIn && <LayoutRoute exact path="/favorites" component={Favorites} layout={Main} />}
          <LayoutRoute exact path="/contact" component={Contact} layout={Main} />
          {!loggedIn && <Route exact path="/login" component={Login} layout={Main} />}
          {loggedIn && <Redirect exact path="/login" to="/" />}
          {loggedIn && <Route exact path="/logout" component={Logout} layout={Main} />}
          {!loggedIn && <LayoutRoute exact path="/register" component={Register} layout={Auth} />}
          {<LayoutRoute path="/unsubscribe/:recipientId/:email" component={Unsubscribe} layout={Auth} />}
          <LayoutRoute exact path="/styleguide" component={StyleGuidePage} layout={Main} />
          <LayoutRoute exact path="/" component={Donors} layout={Main} />
          <LayoutRoute path="*" component={NotFound} layout={Main} />
        </Switch>
      </ErrorBoundary>
    );
  }
}

export default connect(({ shouldUpdate, user }) => ({ shouldUpdate, user }), { getUser })(withRouter(App));
