import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { typography, spacing } from '../../app-theme';
import { getRecipientByIdAndEmail, unsubscribe } from '../../redux/recipient-actions';

const Unsubscribe = () => {
  const [unsubscribing, setUnsubscribing] = useState(true);
  const [unsubscribeSuccessfull, setUnsubscribeSuccessfull] = useState(false);
  const [recipientOK, setRecipientOK] = useState(false);
  const [emailAddress, setEmailAddress] = useState('');

  const queryParameters = useParams();
  const recipientId = queryParameters.recipientId;
  const emailAddressParam = decodeURIComponent(queryParameters.email);
  const recipient = useSelector(({ recipient }) => recipient);

  const dispatch = useDispatch();

  ///
  /// Find the current recipient and verify that the e-mail address provided matches the recipient's e-mail address
  ///
  useEffect(() => {

    if (recipientId) {
      ///
      /// We only want to execute this if we don't already have a recipient
      ///
      if (!recipient.Id) {
        dispatch(getRecipientByIdAndEmail(recipientId, emailAddressParam));
      }

      if (recipient.Id === parseInt(recipientId)) {
        if (recipient.Email.toLowerCase() === emailAddressParam.toLowerCase()) {
          setRecipientOK(true);
          setEmailAddress(recipient.Email);
        }
      }
    }
  }, [dispatch, recipientId, recipient, emailAddress, emailAddressParam]);


  if ('%REACT_APP_ENV%' === 'production') {
    var dataLayer = window.dataLayer = window.dataLayer || [];
    function gtag() {
      dataLayer.push(arguments);
    }
    gtag('config', 'AW-851385468');
    gtag('event', 'conversion', { 'send_to': 'AW-851385468/7vdsCK-bn5QDEPy4_JUD' });
  }

  return (
    <div>
      <div className="auth-layout__form-container">
        {unsubscribing && recipientOK && (
          <div>
            <h2 style={styles.h2}>Unsubscribe</h2>
            <p>To stop receiving information from The World Egg &amp; Sperm Bank at <b>{emailAddress}</b>, please
              click the <b>Unsubscribe</b> button, below.</p>
            <br />
            <button className="button button--primary"
              onClick={async () => {
                const { json } = await dispatch(unsubscribe(recipient));
                setUnsubscribing(false);
                setUnsubscribeSuccessfull(json);
              }}
            >
              Unsubscribe
            </button>
          </div>
        )
        }
        {!recipientOK && (
          <div>
            <h2 style={styles.h2}>Unsubscribe Errror</h2>
            <p>
              Unfortunately, we could not find a recipient with the id # provided in the Url and/or the e-mail address
              provided in the Url does not match the e-mail address
              of the specified user.
            </p>
            <br />
            <p>
              We are unable to stop notifications being sent without a valid recipient and matching e-mail address.
            </p>
            <br />
          </div>
        )}
        {!unsubscribing && !unsubscribeSuccessfull && (
          <div>
            <h2 style={styles.h2}>Unsubscribe Errror</h2>
            <p>
              Unfortunately, we are unable to stop notifications being sent to this e-mail address at this time. Please try again later.
            </p>
          </div>
        )}
        {!unsubscribing && unsubscribeSuccessfull && (
          <div>
            <h2 style={styles.h2}>Confirmed</h2>
            <p>
              You will no longer receive e-mail notifications from the World Egg &amp; Sperm Bank.
            </p>
            <br />
            <p>If you change your mind, please send us a message at <a
              href="info@twesb.com">info@twesb.com</a>, or give us a call on <a
                href='tel:6026781906'>(602) 678-1906.</a>
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

const styles = {
  h2: {
    ...typography.displayL,
    marginBottom: spacing.u4,
  },
  h3: {
    ...typography.displayS,
    marginBottom: spacing.u4,
    fontWeight: typography.regular,
  },
  h4: {
    ...typography.displayS,
    marginBottom: spacing.u1,
  },
};

export default Unsubscribe;
