import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'synfrastructure';

import DonorTraitSelector, { DonorTrait } from './DonorTraitSelector';
import DonorHeightSlider from './DonorHeightSlider';
import { useQuery } from '../../common/utils/hooks';
import Close from '../../common/components/icons/close';
import Plus from '../../common/components/icons/plus';
import Minus from '../../common/components/icons/minus';
import { colors } from '../../app-theme';

import { setShowOptionTypesModal } from '../../redux/ui-actions';
import Button from '../../common/components/buttons/button';
import InfoCircle from '../../common/components/icons/info-circle';
import BabyBundle from '../../common/components/icons/baby-bundle';
import FamilyBundle from '../../common/components/icons/family-bundle';
import PremiumBanked from '../../common/components/icons/premium-banked';
import lookups from '../../common/utils/lookups';
import BankedCustomChoice from '../../common/components/icons/banked-custom-choice';
import { getRecipientById } from '../../redux/recipient-actions';

const DonorTraits = () => {
  const dispatch = useDispatch();
  const [query, setQuery] = useQuery();
  const [characteristicsExpanded, setCharacteristicsExpanded] = useState(true);
  const [characteristicsShowMore, setCharacteristicsShowMore] = useState(
    query.has('hair_texture') ||
    query.has('min_height') ||
    query.has('max_height') ||
    query.has('blood_type') ||
    query.has('blood_type_rh')
  );
  const [eggAvailabilityExpanded, setEggAvailabilityExpanded] = useState(true);
  const [eggAvailabilityShowMore, setEggAvailabilityShowMore] = useState(
    query.has('option_type') || query.has('ancestry')
  );
  const showOptionTypesModal = useSelector(({ ui }) => ui.get('showOptionTypesModal'));
  const isMobile = useSelector(({ browser }) => browser.lessThan.medium);
  const [editingFilters, setEditingFilters] = useState(false);
  const [lookupFilters, setLookupFilters] = useState([]);

  const [hideCountries, setHideCountries] = useState(true);
  const recipient = useSelector(({ recipient }) => recipient);
  const recipientId = useSelector(({ user }) => user.getIn(['data', 'legacy_user_id']));

  const clearFilters = useCallback(() => {
    const newQuery = query
      .delete('from')
      .delete('race')
      .delete('eye_color')
      .delete('hair_color')
      .delete('hair_texture')
      .delete('min_height')
      .delete('max_height')
      .delete('blood_type')
      .delete('blood_type_rh')
      .delete('option_type')
      .delete('ancestry');
    if (!recipient) {
      query.delete('available_in')
    }
    setQuery(newQuery);
    setEditingFilters(true);
  }, [query, setQuery, setEditingFilters, recipient]);

  ///
  /// Find the current recipient (if there is one) - and show/hide the country selections accordingly
  ///
  useEffect(() => {
    if (recipientId) {
      ///
      /// We only want to execute this if we don't already have a recipient
      ///
      if (!recipient.Id) {
        dispatch(getRecipientById(recipientId));
      }

      ///
      /// Hide the country selections if the recipient has a country of residence, but show them otherwise
      ///
      setHideCountries(['US', 'AU', 'CA','UK'].includes(recipient.CountryCode));
    }
    else {
      ///
      /// If there is no recipient Id then this is an anonymous search, so don't hide the country selections
      ///
      setHideCountries(false);
    }
  }, [dispatch, recipientId, recipient, query, setQuery]);

  useEffect(() => {

    let currentFilters = query
      .delete('option')
      .delete('max_height')
      .delete('min_height')
      .toList()
      .toJS()
      .reduce((traits, trait) => traits.concat(Array.isArray(trait) ? trait : [trait]), [])
      .map(t => lookups[t])
      .filter(Boolean);

    const maxHeight = query.get('max_height');
    const minHeight = query.get('min_height');

    if (maxHeight && minHeight) {
      currentFilters.push(`${lookups[minHeight]} to ${lookups[maxHeight]}`);
    } else if (maxHeight) {
      currentFilters.push(`< ${lookups[maxHeight]}`);
    } else if (minHeight) {
      currentFilters.push(`> ${lookups[minHeight]}`);
    }

    setLookupFilters(currentFilters);
  }, [query, setLookupFilters]);

  return (
    <div className="donor-traits">
      <h4 className="brandPurple">Select Traits</h4>

      {isMobile && lookupFilters.length > 0 && (
        <p>
          <strong>Filtered by:</strong> {lookupFilters.slice(0, 5).join(', ')}
          {lookupFilters.length > 5 && ', etc...'}
        </p>
      )}

      {(!isMobile || editingFilters) && (
        <>
          <p>
            Use the following options to filter donors based on your preferences. Keeping your search criteria to a
            minimum will allow a wider selection of donors
          </p>

          {lookupFilters.length > 0 && (
            <Button
              buttonType="primary-alt"
              className="button--size--medium button--full-width margin-bottom__m"
              onClick={clearFilters}
            >
              Clear Filters
            </Button>
          )}

          <div className="donor-traits__selectors">
            <h6 className="allCaps" onClick={() => setCharacteristicsExpanded(!characteristicsExpanded)}>
              Characteristics
              <span className="toggle">
                {!characteristicsExpanded ? (
                  <Plus size={20} fill={colors.darkGray} />
                ) : (
                  <Minus fill={colors.darkGray} size={20} />
                )}
              </span>
            </h6>
            {characteristicsExpanded && (
              <>
                <DonorTraitSelector label="Race" trait="race" plural>
                  <DonorTrait label="Asian" value="840" />
                  <DonorTrait label="Black" value="841" />
                  <DonorTrait label="Caucasian" value="843" />
                  <DonorTrait label="East Indian" value="844" />
                  <DonorTrait label="Hawaiian" value="842" />
                  <DonorTrait label="Hispanic" value="849" />
                  <DonorTrait label="Middle Eastern" value="850" />
                  <DonorTrait label="Native American" value="851" />
                </DonorTraitSelector>
                <DonorTraitSelector label="Eye Color" trait="eye_color" plural>
                  <DonorTrait label="Black" value="33" />
                  <DonorTrait label="Blue" value="34" />
                  <DonorTrait label="Brown" value="37" />
                  <DonorTrait label="Green" value="35" />
                  <DonorTrait label="Hazel" value="36" />
                </DonorTraitSelector>
                <DonorTraitSelector label="Hair Color" trait="hair_color" plural>
                  <DonorTrait label="Black" value="38" />
                  <DonorTrait label="Blonde" value="39" />
                  <DonorTrait label="Brown" value="40" />
                  <DonorTrait label="Red" value="41" />
                </DonorTraitSelector>
                {characteristicsShowMore && (
                  <DonorTraitSelector label="Hair Texture" trait="hair_texture" plural>
                    <DonorTrait label="Average" value="46" />
                    <DonorTrait label="Curly" value="44" />
                    <DonorTrait label="Straight" value="42" />
                    <DonorTrait label="Thick" value="47" />
                    <DonorTrait label="Thin" value="45" />
                    <DonorTrait label="Wavy" value="43" />
                  </DonorTraitSelector>
                )}
                {characteristicsShowMore && <DonorHeightSlider trait="height" label="Height" />}
                {characteristicsShowMore && (
                  <DonorTraitSelector label="Blood Type" trait="blood_type" plural>
                    <DonorTrait label="A" value="5150" />
                    <DonorTrait label="AB" value="5155" />
                    <DonorTrait label="B" value="5160" />
                    <DonorTrait label="O" value="5165" />
                  </DonorTraitSelector>
                )}
                {characteristicsShowMore && (
                  <DonorTraitSelector label="Rh Factor" trait="blood_type_rh" plural>
                    <DonorTrait label="Positive" value="5100" />
                    <DonorTrait label="Negative" value="5105" />
                  </DonorTraitSelector>
                )}
                {!characteristicsShowMore && (
                  <span className="toggleOptions" onClick={() => setCharacteristicsShowMore(true)}>
                    More Options
                  </span>
                )}
                {characteristicsShowMore && (
                  <span className="toggleOptions" onClick={() => setCharacteristicsShowMore(false)}>
                    Less Options
                  </span>
                )}
              </>
            )}
          </div>

          <div className="donor-traits__selectors">
            <h6 className="allCaps" onClick={() => setEggAvailabilityExpanded(!eggAvailabilityExpanded)}>
              {!hideCountries && <span>Egg Availability & Type</span>}
              {hideCountries && <span>Egg Type</span>}
              <span className="toggle">
                {!eggAvailabilityExpanded ? (
                  <Plus size={20} fill={colors.darkGray} />
                ) : (
                  <Minus fill={colors.darkGray} size={20} />
                )}
              </span>
            </h6>
            {eggAvailabilityExpanded && (
              <>
                {!hideCountries && <DonorTraitSelector label="Your Country of Residence" trait="available_in">
                  <DonorTrait label="Available In the US" value="US" />
                  <DonorTrait label="Available In Australia" value="AU" />
                  <DonorTrait label="Available In the UK" value="UK" />
                  <DonorTrait label="Available In Canada" value="CA" />
                  <DonorTrait label="Available In Other Countries" value="XX" />
                </DonorTraitSelector>
                }
                {eggAvailabilityShowMore && (
                  <>
                    <DonorTraitSelector
                      label={size => (
                        <span>
                          Option Type{' '}
                          {!Boolean(size) && (
                            <span>
                              (
                              <span
                                className="link"
                                onClick={e => {
                                  e.stopPropagation();
                                  e.nativeEvent.stopImmediatePropagation();
                                  dispatch(setShowOptionTypesModal(true));
                                }}
                              >
                                What's this?
                              </span>
                              )
                            </span>
                          )}
                        </span>
                      )}
                      trait="option_type"
                    >
                      <DonorTrait label="Premium" value="premium">
                        <PremiumBanked fill={colors.brandPurple} />
                      </DonorTrait>
                      <DonorTrait label="Baby Bundle" value="baby-bundle">
                        <BabyBundle fill={colors.brandPurple} />
                      </DonorTrait>
                      <DonorTrait label="Fresh Custom Choice" value="family-bundle">
                        <FamilyBundle fill={colors.brandPurple} />
                      </DonorTrait>
                      <DonorTrait label="Frozen Custom Choice" value="banked-custom-choice">
                        <BankedCustomChoice fill={colors.brandPurple} size={20} />
                      </DonorTrait>
                    </DonorTraitSelector>
                    <DonorTraitSelector
                      label="Ancestry"
                      trait="ancestry"
                      className="trait-selector__ancestry"
                      isSearchable
                    >
                      <DonorTrait label="African American" value="853" />
                      <DonorTrait label="Alsace" value="854" />
                      <DonorTrait label="Argentinean" value="855" />
                      <DonorTrait label="Armenian" value="856" />
                      <DonorTrait label="Austrian" value="857" />
                      <DonorTrait label="Belgian" value="858" />
                      <DonorTrait label="Bolivian" value="859" />
                      <DonorTrait label="Brazilian" value="860" />
                      <DonorTrait label="Bulgarian" value="861" />
                      <DonorTrait label="Canadian" value="862" />
                      <DonorTrait label="Chilean" value="863" />
                      <DonorTrait label="Chinese" value="864" />
                      <DonorTrait label="Colombian" value="865" />
                      <DonorTrait label="Costa Rican" value="866" />
                      <DonorTrait label="Creole" value="867" />
                      <DonorTrait label="Croatian" value="868" />
                      <DonorTrait label="Czech" value="869" />
                      <DonorTrait label="Danish" value="870" />
                      <DonorTrait label="Dutch" value="871" />
                      <DonorTrait label="East Indian" value="872" />
                      <DonorTrait label="Ecuadorian" value="873" />
                      <DonorTrait label="Egyptian" value="874" />
                      <DonorTrait label="English" value="875" />
                      <DonorTrait label="Ethiopian" value="876" />
                      <DonorTrait label="Filipino" value="877" />
                      <DonorTrait label="Finnish" value="878" />
                      <DonorTrait label="French" value="879" />
                      <DonorTrait label="French-Canadian" value="880" />
                      <DonorTrait label="German" value="881" />
                      <DonorTrait label="Greek" value="882" />
                      <DonorTrait label="Guatemalan" value="883" />
                      <DonorTrait label="Honduran" value="884" />
                      <DonorTrait label="Hungarian" value="885" />
                      <DonorTrait label="Icelandic" value="886" />
                      <DonorTrait label="Iranian" value="887" />
                      <DonorTrait label="Irish" value="888" />
                      <DonorTrait label="Israeli" value="889" />
                      <DonorTrait label="Italian" value="890" />
                      <DonorTrait label="Jamaican" value="891" />
                      <DonorTrait label="Japanese" value="892" />
                      <DonorTrait label="Jewish" value="893" />
                      <DonorTrait label="Korean" value="894" />
                      <DonorTrait label="Lebanese" value="895" />
                      <DonorTrait label="Mexican" value="896" />
                      <DonorTrait label="Moroccan" value="897" />
                      <DonorTrait label="Native American" value="898" />
                      <DonorTrait label="Nicaraguan" value="899" />
                      <DonorTrait label="Norwegian" value="900" />
                      <DonorTrait label="Pacific Islander" value="901" />
                      <DonorTrait label="Pakistani" value="902" />
                      <DonorTrait label="Palestinian" value="903" />
                      <DonorTrait label="Panamanian" value="904" />
                      <DonorTrait label="Paraguayan" value="905" />
                      <DonorTrait label="Persian" value="906" />
                      <DonorTrait label="Polish" value="907" />
                      <DonorTrait label="Portuguese" value="908" />
                      <DonorTrait label="Puerto Rican" value="909" />
                      <DonorTrait label="Romanian" value="910" />
                      <DonorTrait label="Russian" value="911" />
                      <DonorTrait label="Scandinavian" value="912" />
                      <DonorTrait label="Scottish" value="913" />
                      <DonorTrait label="Slavic" value="914" />
                      <DonorTrait label="Slovakian" value="915" />
                      <DonorTrait label="South African" value="916" />
                      <DonorTrait label="Spanish" value="917" />
                      <DonorTrait label="Swedish" value="918" />
                      <DonorTrait label="Swiss" value="919" />
                      <DonorTrait label="Taiwanese" value="920" />
                      <DonorTrait label="Thai" value="921" />
                      <DonorTrait label="Turkish" value="922" />
                      <DonorTrait label="Ukrainian" value="923" />
                      <DonorTrait label="Uruguayan" value="924" />
                      <DonorTrait label="Venezuelan" value="925" />
                      <DonorTrait label="Vietnamese" value="926" />
                      <DonorTrait label="Welsh" value="927" />
                      <DonorTrait label="Yugoslavian" value="928" />
                      <DonorTrait label="Zairian" value="929" />
                    </DonorTraitSelector>
                    <span className="toggleOptions" onClick={() => setEggAvailabilityShowMore(false)}>
                      Less Options
                    </span>
                  </>
                )}
                {!eggAvailabilityShowMore && (
                  <span className="toggleOptions" onClick={() => setEggAvailabilityShowMore(true)}>
                    More Options
                  </span>
                )}
              </>
            )}
          </div>
        </>
      )}

      {isMobile && (
        <div>
          {lookupFilters.length === 0 && (
            <>
              {!editingFilters && (
                <Button className="button--full-width" onClick={() => setEditingFilters(true)}>
                  Filter by Traits
                </Button>
              )}
              {editingFilters && (
                <Button buttonType="primary-alt" onClick={() => setEditingFilters(false)}>
                  Cancel
                </Button>
              )}
            </>
          )}
          {lookupFilters.length > 0 && (
            <div className="button-group-row button-group">
              <Button className="button--size--medium" onClick={() => setEditingFilters(!editingFilters)}>
                {editingFilters ? 'Apply' : 'Edit'} Filters
              </Button>
              <Button buttonType="primary-alt" className="button--size--medium" onClick={clearFilters}>
                Clear Filters
              </Button>
            </div>
          )}
        </div>
      )}

      <Modal
        closeButton={
          <span role="img" aria-label="Close Modal">
            <Close size={40} />
          </span>
        }
        isVisible={showOptionTypesModal}
        onClose={() => dispatch(setShowOptionTypesModal(false))}
      >
        <h3 className="modal__header">
          <InfoCircle size={28} fill={colors.brandPurple} />
          Option Types
        </h3>
        <div className="modal__content">
          <h4 className="subheader">
            <PremiumBanked size={24} fill={colors.brandPurple} /> Premium Banked Eggs
          </h4>
          <p>
            These donors have already been screened, stimulated, and eggs retrieved and frozen. Select your eggs and we
            ship them directly to your clinic for warming, fertilization, and transfer.
          </p>
          <h4 className="subheader">
            <BabyBundle size={24} fill={colors.brandPurple} /> Baby Bundles
          </h4>
          <p>
            Create a custom cohort of 6 from lots of 1 - 5 eggs remaining from our premium donors at a reduced rate.
          </p>
          <h4 className="subheader">
            <FamilyBundle size={24} fill={colors.brandPurple} /> Fresh Custom Choice
          </h4>
          <p>
            In this process, you select a Fresh Custom Choice™ egg donor and we begin a fresh cycle consisting of screening, stimulating, and retrieving her eggs.
            You will have your partner’s or donor sperm shipped directly to us for fertilization of fresh donor eggs and the resulting embryos will
            be frozen and shipped to your clinic.
          </p>
          <h4 className="subheader">
            <BankedCustomChoice size={20} fill={colors.brandPurple} /> Frozen Custom Choice
          </h4>
          <p>
            With the Frozen Custom Choice ™ Embryo Option, you select an egg donor that already has eggs frozen in the bank. We thaw and fertilize the eggs
            using donor sperm from The World Egg and Sperm Bank or using your partner’s sperm or the donor sperm you ship to us. Once the donor eggs are fertilized,
            the resulting embryos will be vitrified and shipped to your clinic to use in your treatment.
          </p>
        </div>
      </Modal>
    </div>
  );
};

export default DonorTraits;
