import React, { useMemo } from 'react';
import { Canvas, Document, Font, Image, Link, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { usePhotos } from '../../common/utils/hooks';
import WhiteLogo from '../../common/images/TWESB_White.png';
import { colors } from '../../app-theme';

Font.register({
  family: 'Roboto Slab',
  fonts: [
    { src: 'https://fonts.gstatic.com/s/robotoslab/v11/BngbUXZYTXPIvIBgJJSb6s3BzlRRfKOFbvjojISmb2RlV9Su1cai.woff' },
    {
      src: 'https://fonts.gstatic.com/s/robotoslab/v11/BngbUXZYTXPIvIBgJJSb6s3BzlRRfKOFbvjoa4Omb2RlV9Su1cai.woff',
      fontWeight: 700,
    },
  ],
});
Font.register({
  family: 'Muli',
  fonts: [
    { src: 'https://fonts.gstatic.com/s/muli/v20/7Aulp_0qiz-aVz7u3PJLcUMYOFnOkEk30e6fwniDtzM.woff' },
    { src: 'https://fonts.gstatic.com/s/muli/v20/7Aulp_0qiz-aVz7u3PJLcUMYOFlOl0k30e6fwniDtzM.woff', fontWeight: 700 },
  ],
});

const styles = StyleSheet.create({
  footer: {
    bottom: 24,
    position: 'absolute',
    left: 24,
  },
  header: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    backgroundColor: colors.brandPurple,
    fontSize: 14,
    padding:5,
    marginTop:5,
    marginBottom:5
  },
  heading: {
    color: '#ffffff',
    fontFamily: 'Roboto Slab',
  },
  imageSmall: { height: 110, marginBottom: 6, marginRight: 6, width: 110 },
  page: {
    flexDirection: 'column',
    padding: 24,
    paddingBottom: 62,
  },
  sectionHeading: {
    fontFamily: 'Muli',
    color: '#652365',
    fontSize: 12,
    marginBottom: 12,
    fontWeight: 700,
  },
  table: { flexDirection: 'row', flexWrap: 'wrap' },
  tableCell: { boxSizing: 'border-box', flexGrow: 1 },
  tableCell2Col: { width: '50%', marginBottom: 12 },
  tableCell3Col: { width: '33.33%', marginBottom: 12 },
  text: {
    fontFamily: 'Muli',
    fontSize: 8,
  },
  flexTable: { display: 'table', width: 'auto' },
  tableRow: { margin: 'auto', flexDirection: 'row' },
});

const Footer = () => (
  <View style={styles.footer} fixed wrap={false}>
    <Hr />
    <View style={{ flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
      <Text style={[styles.text, { fontSize: 8 }]}>
        Downloaded from <Link src="https://theworldeggandspermbank.com">https://theworldeggandspermbank.com</Link>
      </Text>
      <Text
        style={[styles.text, { fontSize: 8 }]}
        render={({ pageNumber, totalPages }) => `Page ${pageNumber} of ${totalPages}`}
        fixed
      />
    </View>
  </View>
);

const Header = ({ donorId }) => (
  <View fixed wrap={false}>
    <View style={styles.header}>
      <Image src={WhiteLogo} style={{ height: 40 }} />
      <Text style={styles.heading}>Donor ID: {donorId}</Text>
    </View>
    <Hr />
  </View>
);

const Hr = () => (
  <Canvas
    style={{ height: 28, width: 547 }}
    paint={painter => {
      painter.lineWidth = 4;
      return painter
        .moveTo(0, 16)
        .lineTo(547, 16)
        .fill('#C5C6E0');
    }}
  />
);

const FamilyHistoryRow = ({ even, first, label, last, values }) => (
  <View style={styles.tableRow}>
    <View
      style={[
        styles.tableCell,
        {
          backgroundColor: '#652365',
          width: '20%',
          borderTopLeftRadius: first ? 6 : 0,
          borderBottomLeftRadius: last ? 6 : 0,
          borderWidth: 0,
          borderColor: 'transparent',
        },
      ]}
    >
      <Text
        style={[
          styles.text,
          { color: 'white', fontFamilyi: 'Muli', fontWeight: 700, padding: 12, textTransform: 'uppercase' },
        ]}
      >
        {label}
      </Text>
    </View>
    {values.map((value, index) => (
      <View
        key={index}
        style={[
          styles.tableCell,
          {
            borderBottomRightRadius: last && index === values.length - 1 ? 6 : 0,
            borderRightColor: '#D8D8D8',
            borderRightWidth: 1,
            borderTopColor: '#D8D8D8',
            borderTopRightRadius: first && index === values.length - 1 ? 6 : 0,
            borderTopWidth: 1,
            width: `${80 / values.length}%`,
            borderBottomWidth: last ? 1 : 0,
            borderBottomColor: last ? '#D8D8D8' : null,
            backgroundColor: even ? '#F7F7F7' : 'white',
          },
        ]}
      >
        <Text style={[styles.text, { fontSize: 8, fontWeight: first ? 700 : null, textAlign: 'center', padding: 12 }]}>
          {value || ' '}
        </Text>
      </View>
    ))}
  </View>
);

const FamilyHistoryTable = ({ familyMembers, label }) => (
  <View wrap={false} style={{ marginBottom: 15 }}>
    <Text style={[styles.text, { fontSize: 10, fontWeight: 700, marginBottom: 8 }]}>{label}</Text>
    <View style={[styles.flexTable, { borderRadius: 6 }]}>
      <FamilyHistoryRow first label="Relation" values={familyMembers.map(fm => fm.get('relationship_to_donor'))} />
      <FamilyHistoryRow label="Age" values={familyMembers.map(fm => fm.get('age'))} />
      <FamilyHistoryRow
        even
        label="Age at Death / Cause of Death"
        values={familyMembers.map(fm => fm.get('age_at_death'))}
      />
      <FamilyHistoryRow label="Height" values={familyMembers.map(fm => fm.get('height'))} />
      <FamilyHistoryRow even label="Weight" values={familyMembers.map(fm => fm.get('weight_and_units'))} />
      <FamilyHistoryRow label="Race" values={familyMembers.map(fm => fm.get('ethnicity'))} />
      <FamilyHistoryRow even label="Eye Color" values={familyMembers.map(fm => fm.get('eye_color'))} />
      <FamilyHistoryRow label="Hair Color" values={familyMembers.map(fm => fm.get('hair_color'))} />
      <FamilyHistoryRow even label="Skin Type" values={familyMembers.map(fm => fm.get('skin_type'))} />
      <FamilyHistoryRow label="Skills" values={familyMembers.map(fm => fm.get('skills'))} />
      <FamilyHistoryRow even label="Occupation" values={familyMembers.map(fm => fm.get('occupation'))} />
      <FamilyHistoryRow label="Education" last values={familyMembers.map(fm => fm.get('education'))} />
    </View>
  </View>
);

const MedicalHistoryRow = ({ even, familyMembers, label, last }) => (
  <View style={styles.tableRow}>
    <View
      style={[
        styles.tableCell,
        {
          backgroundColor: even ? '#F7F7F7' : 'white',
          borderBottomColor: '#D8D8D8',
          borderBottomLeftRadius: last ? 6 : 0,
          borderStyle: 'solid',
          borderBottomWidth: 1,
          borderLeftColor: '#D8D8D8',
          borderLeftWidth: 1,
          width: '50%',
        },
      ]}
    >
      <Text style={[styles.text, { paddingHorizontal: 12, paddingVertical: 8 }]}>{label}</Text>
    </View>
    <View
      style={[
        styles.tableCell,
        {
          backgroundColor: even ? '#F7F7F7' : 'white',
          borderBottomColor: '#D8D8D8',
          borderBottomRightRadius: last ? 6 : 0,
          borderStyle: 'solid',
          borderBottomWidth: 1,
          borderRightColor: '#D8D8D8',
          borderRightWidth: 1,
          width: '50%',
        },
      ]}
    >
      <Text style={[styles.text, { paddingHorizontal: 12, paddingVertical: 8 }]}>{familyMembers}</Text>
    </View>
  </View>
);

const MedicalHistoryTable = ({ issues, label, medicalIssueDetails }) => (
  <View wrap={false} style={{ marginBottom: 15 }}>
    <Text style={[styles.text, { fontSize: 10, fontWeight: 700, marginBottom: 8 }]}>{label}</Text>
    <View style={[styles.flexTable, { backgroundColor: '#652365', borderRadius: 6 }]}>
      <View style={styles.tableRow}>
        <View style={[styles.tableCell, { width: '50%' }]}>
          <Text
            style={[
              styles.text,
              { color: 'white', fontFamily: 'Roboto Slab', fontWeight: 700, paddingHorizontal: 12, paddingVertical: 8 },
            ]}
          >
            Medical Condition
          </Text>
        </View>
        <View style={[styles.tableCell, { width: '50%' }]}>
          <Text
            style={[
              styles.text,
              { color: 'white', fontFamily: 'Roboto Slab', fontWeight: 700, paddingHorizontal: 12, paddingVertical: 8 },
            ]}
          >
            Who
          </Text>
        </View>
      </View>
      {issues.map((issue, index) => (
        <MedicalHistoryRow
          even={index % 2 === 0}
          familyMembers={getFamilyMembers(Array.isArray(issue) ? issue[0] : issue, medicalIssueDetails)}
          key={Array.isArray(issue) ? issue[0] : issue}
          last={index === issues.length - 1}
          label={Array.isArray(issue) ? issue[1] : issue}
        />
      ))}
    </View>
  </View>
);

const getFamilyMembers = (issue, issues) => {
  let matches = issues
    .filter(medicalIssue => medicalIssue.get('question_text') === issue)
    .filter(medicalIssue => medicalIssue.get('answer') === 'Yes')
    // @TODO What are the "No" family members supposed to represent?
    .filter(medicalIssue => medicalIssue.get('family_member') !== 'No')
    .sortBy(medicalIssue => medicalIssue.get('family_member_id'));

  matches = matches.map(medicalIssue => {
    if (medicalIssue.get('family_member') === 'You') {
      medicalIssue = medicalIssue.set('family_member', 'Donor');
    }

    return medicalIssue;
  });

  matches = matches.map(medicalIssue => medicalIssue.get('family_member'));

  if (!matches.size) {
    matches = matches.push('None');
  }

  return matches.toJS().join(', ');
};

const DonorProfilePdf = ({ donor, locale }) => {
  const data = useMemo(() => donor.toJS(), [donor]);
  const { photos, primary } = usePhotos(donor);

  const family = donor.get('family_members');
  const medicalIssueDetails = donor.get('medical_issue_details');

  const [father, mGrandfather, mGrandmother, mother, pGrandfather, pGrandmother, siblings] = useMemo(
    () => [
      family.find(relation => relation.get('relationship_to_donor') === 'Father', null, new Map()),
      family.find(relation => relation.get('relationship_to_donor') === 'Maternal Grand Father', null, new Map()),
      family.find(relation => relation.get('relationship_to_donor') === 'Maternal Grand Mother', null, new Map()),
      family.find(relation => relation.get('relationship_to_donor') === 'Mother', null, new Map()),
      family.find(relation => relation.get('relationship_to_donor') === 'Paternal Grand Father', null, new Map()),
      family.find(relation => relation.get('relationship_to_donor') === 'Paternal Grand Mother', null, new Map()),
      family.filter(relation => relation.get('relationship_to_donor').includes('Sibling')),
    ],
    [family]
  );

  const getDonorGpa = () => {
    const gpa = data.college_gpa || data.school_gpa;
    switch (gpa) {
      case 5:
        return '<3.0';
      case 6:
        return '3.0-3.5';
      case 7:
        return '3.5-4.0';
      case 8:
        return '>4.0';
      case null:
        return 'N/A';
      default:
        return gpa;
    };
  };
  const donorGpa = getDonorGpa();

  const timestamp = new Date().getTime();

  return (
    <Document title={`Donor ${data.donor_id} Profile`} creator="The World Egg and Sperm Bank" producer="The World Egg and Sperm Bank">
      <Page size="A4" style={styles.page}>
        <Header donorId={data.donor_id} />
        <View wrap={false}>
          <Text style={styles.sectionHeading}>Photos</Text>
          <View style={{ flexDirection: 'row', marginBottom: 6, width: 547 }}>
            <View style={{ marginRight: 6, width: 164 }}>
              <Image src={`${primary.full}?t=${timestamp}`} cache={false} />
            </View>
            <View style={{ flexDirection: 'row', flexWrap: 'wrap', width: 377 }}>
              {
              photos
                .filter(photo => !photo.get('primary'))
                .take(15)
                .map(photo => (
                  <Image
                    key={photo.get('id')}
                    src={`${photo.get('full')}?t=${timestamp}`}
                    style={styles.imageSmall}
                    cache={false}
                  />
                ))}
            </View>
          </View>
          <Hr />
        </View>

        <View wrap={false}>
          <Text style={styles.sectionHeading}>Characteristics</Text>
          <View style={styles.table}>
            <View style={[styles.tableCell, styles.tableCell3Col]}>
              <Text style={[styles.text, { fontWeight: 700 }]}>Eye Color</Text>
              <Text style={styles.text}>{data.eye_color || 'N/A'}</Text>
            </View>
            <View style={[styles.tableCell, styles.tableCell3Col]}>
              <Text style={[styles.text, { fontWeight: 700 }]}>Height</Text>
              <Text style={styles.text}>{data.height || 'N/A'}</Text>
            </View>
            <View style={[styles.tableCell, styles.tableCell3Col]}>
              <Text style={[styles.text, { fontWeight: 700 }]}>Race</Text>
              <Text style={styles.text}>{data.ethnicity || 'N/A'}</Text>
            </View>

            <View style={[styles.tableCell, styles.tableCell3Col]}>
              <Text style={[styles.text, { fontWeight: 700 }]}>Hair Color</Text>
              <Text style={styles.text}>{data.hair_color || 'N/A'}</Text>
            </View>
            <View style={[styles.tableCell, styles.tableCell3Col]}>
              <Text style={[styles.text, { fontWeight: 700 }]}>Weight</Text>
              <Text style={styles.text}>{data.weight_and_units || 'N/A'}</Text>
            </View>
            <View style={[styles.tableCell, styles.tableCell3Col]}>
              <Text style={[styles.text, { fontWeight: 700 }]}>Mother's Ancestry</Text>
              <Text style={styles.text}>{data.ancestry_mother || 'N/A'}</Text>
            </View>

            <View style={[styles.tableCell, styles.tableCell3Col]}>
              <Text style={[styles.text, { fontWeight: 700 }]}>Hair Texture</Text>
              <Text style={styles.text}>{data.hair_texture || 'N/A'}</Text>
            </View>
            <View style={[styles.tableCell, styles.tableCell3Col]}>
              <Text style={[styles.text, { fontWeight: 700 }]}>Body Type</Text>
              <Text style={styles.text}>{data.body_type || 'N/A'}</Text>
            </View>
            <View style={[styles.tableCell, styles.tableCell3Col]}>
              <Text style={[styles.text, { fontWeight: 700 }]}>Father's Ancestry</Text>
              <Text style={styles.text}>{data.ancestry_father || 'N/A'}</Text>
            </View>

            <View style={[styles.tableCell, styles.tableCell3Col]}>
              <Text style={[styles.text, { fontWeight: 700 }]}>Skin Type</Text>
              <Text style={styles.text}>{data.skin_type || 'N/A'}</Text>
            </View>
            <View style={[styles.tableCell, styles.tableCell3Col]}>
              <Text style={[styles.text, { fontWeight: 700 }]}>Blood Type</Text>
              <Text style={styles.text}>{data.blood_type || 'N/A'}</Text>
            </View>
            <View style={[styles.tableCell, styles.tableCell3Col]}>
              <Text style={[styles.text, { fontWeight: 700 }]}>Rh Factor</Text>
              <Text style={styles.text}>{data.blood_type_rh || 'N/A'}</Text>
            </View>
          </View>
          <Hr />
        </View>

        <View wrap={false}>
          <Text style={styles.sectionHeading}>Donor Background</Text>
          <View style={styles.table}>
            <View style={[styles.tableCell, styles.tableCell3Col]}>
              <Text style={[styles.text, { fontWeight: 700 }]}>State/Province</Text>
              <Text style={styles.text}>{data.state_or_province || 'N/A'}</Text>
            </View>
            <View style={[styles.tableCell, styles.tableCell3Col]}>
              <Text style={[styles.text, { fontWeight: 700 }]}>Country</Text>
              <Text style={styles.text}>{data.country || 'N/A'}</Text>
            </View>
            <View style={[styles.tableCell, styles.tableCell3Col]}>
              <Text style={[styles.text, { fontWeight: 700 }]}>Occupation</Text>
              <Text style={styles.text}>{data.occupation || 'N/A'}</Text>
            </View>

            <View style={[styles.tableCell, styles.tableCell3Col]}>
              <Text style={[styles.text, { fontWeight: 700 }]}>Education Level</Text>
              <Text style={styles.text}>{data.education_level_public || 'N/A'}</Text>
            </View>
            <View style={[styles.tableCell, styles.tableCell3Col]}>
              <Text style={[styles.text, { fontWeight: 700 }]}>School GPA</Text>
              <Text style={styles.text}>{donorGpa || 'N/A'}</Text>
            </View>
            <View style={[styles.tableCell, styles.tableCell3Col]}>
              <Text style={[styles.text, { fontWeight: 700 }]}>SAT/ACT</Text>
              <Text style={styles.text}>
                {data.sat || 'N/A'} / {data.act || 'N/A'}
              </Text>
            </View>
          </View>
        </View>

        <View>
          <Text style={styles.sectionHeading}>Donor Description</Text>
          <View style={styles.table}>
            <View style={[styles.tableCell, styles.tableCell2Col]} wrap={false}>
              <Text style={[styles.text, { fontSize: 9, fontWeight: 700, marginBottom: 2 }]}>Talents</Text>
              <Text style={[styles.text, { width: '90%' }]}>{data.talents || 'N/A'}</Text>
            </View>
            <View style={[styles.tableCell, styles.tableCell2Col]} wrap={false}>
              <Text style={[styles.text, { fontSize: 9, fontWeight: 700, marginBottom: 2 }]}>Character</Text>
              <Text style={[styles.text, { width: '90%' }]}>{data.character || 'N/A'}</Text>
            </View>

            <View style={[styles.tableCell, styles.tableCell2Col]} wrap={false}>
              <Text style={[styles.text, { fontSize: 9, fontWeight: 700, marginBottom: 2 }]}>Favorite</Text>
              <Text style={[styles.text, { width: '90%' }]}>{data.favorite || 'N/A'}</Text>
            </View>
            <View style={[styles.tableCell, styles.tableCell2Col]} wrap={false}>
              <Text style={[styles.text, { fontSize: 9, fontWeight: 700, marginBottom: 2 }]}>Goals</Text>
              <Text style={[styles.text, { width: '90%' }]}>{data.goals || 'N/A'}</Text>
            </View>

            <View style={[styles.tableCell, styles.tableCell2Col]} wrap={false}>
              <Text style={[styles.text, { fontSize: 9, fontWeight: 700, marginBottom: 2 }]}>Interest</Text>
              <Text style={[styles.text, { width: '90%' }]}>{data.interest || 'N/A'}</Text>
            </View>
            <View style={[styles.tableCell, styles.tableCell2Col, { marginBottom: 0 }]} wrap={false}>
              <Text style={[styles.text, { fontSize: 9, fontWeight: 700, marginBottom: 2 }]}>Exercise</Text>
              <Text style={[styles.text, { width: '90%' }]}>{data.exercise || 'N/A'}</Text>
            </View>
          </View>
          <Hr />
        </View>

        <View wrap={false}>
          <Text style={styles.sectionHeading}>Medical History</Text>

          <MedicalHistoryTable
            issues={[
              'Deafness before age 50',
              'Cataracts before age 50',
              'Color blindness',
              'Blindness',
              'Glaucoma',
              'Nearsighted',
              'Farsighted',
              'Contacts or Glasses',
            ]}
            label="Vision & Hearing"
            medicalIssueDetails={medicalIssueDetails}
          />
        </View>

        <MedicalHistoryTable
          issues={['Heart Attack', 'Heart Defects', 'Heart Murmur', 'High Blood Pressure', 'Asthma', 'Emphysema']}
          label="Cardiovascular & Respiratory Health"
          medicalIssueDetails={medicalIssueDetails}
        />

        <MedicalHistoryTable
          issues={[
            'Migraines',
            'Dementia',
            'Mentally Handicapped',
            "Tourette’s Syndrome",
            'Epilepsy',
            'Hydrocephalus',
            "Huntington’s Chorea",
          ]}
          label="Central Nervous System"
          medicalIssueDetails={medicalIssueDetails}
        />

        <MedicalHistoryTable
          issues={[
            // [
            //   'Thalassemia, pulmonary embolism, blood clot, iron deficiency anemia, thrombocytopenia, DVT, anemia (any type), Von Willebrand disease',
            //   'Thalassemia, pulmonary embolism, blood clot, iron deficiency anemia, thrombocytopenia, DVT, anemia (any type), Von Willebrand disease',
            // ],
            'Thalassemia, pulmonary embolism, blood clot, iron deficiency anemia, thrombocytopenia, DVT, anemia [any type], Von Willebrand disease',
            'Sickle Cell Anemia',
            'Hemophilia',
            "Gaucher’s Disease",
            'Thyroid Disease',
            'Adrenal Dysfunction',
          ]}
          label="Blood & Endocrine"
          medicalIssueDetails={medicalIssueDetails}
        />

        <MedicalHistoryTable
          issues={[
            "Crohn’s Disease",
            'Liver Disease',
            'Kidney Disease',
            'Cystic Fibrosis',
            '(Type 1) Diabetes',
            "Wilson’s Disease",
            'Cleft Palette',
          ]}
          label="Digestive/GI"
          medicalIssueDetails={medicalIssueDetails}
        />

        <MedicalHistoryTable
          issues={[
            'Skin Disorder (excluding exzema, psoriasis, acne)',
            'Skin pigmentation abnormality',
            ["Lou Gehrigs's Disease", "Lou Gehrig's Disease"],
            'Spina Bifida',
            'Muscular Dystrophy',
            'Osteoporosis',
            'Skeletal Dysplasia',
            'Arthritis',
          ]}
          label="Skin & Musculoskeletal"
          medicalIssueDetails={medicalIssueDetails}
        />

        <MedicalHistoryTable
          issues={[
            'Immune Deficiency Disorder',
            'Multiple Sclerosis',
            'Lupus',
            'Hepatitis A',
            'Hepatitis B',
            'Other Conditions',
          ]}
          label="Immune System & Infection"
          medicalIssueDetails={medicalIssueDetails}
        />

        <MedicalHistoryTable
          issues={[
            'Lung Cancer',
            'Breast Cancer',
            'Thyroid Cancer',
            'GI Cancer  (esophagus, stomach, intestine, colon, pancreatic, liver, gallbladder)',
            'Cervical Cancer',
            'Skin Cancer',
            'Ovarian Cancer',
            'Uterine Cancer',
            'Leukemia',
            'Other Cancer',
          ]}
          label="Cancer"
          medicalIssueDetails={medicalIssueDetails}
        />

        <View wrap={false}>
          <MedicalHistoryTable
            issues={[
              ['Bipolar disorder', 'Bipolar disorder'],
              ['Schizophrenia', 'Schizophrenia'],
              'Alcoholism',
              'Drug Addiction',
            ]}
            label="Mental Health & Substance Abuse"
            medicalIssueDetails={medicalIssueDetails}
          />
          <Hr />
        </View>

        <View wrap={false}>
          <Text style={styles.sectionHeading}>Family History</Text>
          <FamilyHistoryTable label="Parents" familyMembers={[father, mother]} />
        </View>
        <FamilyHistoryTable
          label="Grandparents"
          familyMembers={[mGrandmother, mGrandfather, pGrandmother, pGrandfather]}
        />
        {siblings.size > 0 && <FamilyHistoryTable label="Siblings" familyMembers={siblings.toArray()} />}

        <Footer />
      </Page>
    </Document>
  );
};

export default DonorProfilePdf;
