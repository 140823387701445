import { fromJS } from 'immutable';
import store from 'store2';

import config from '../config';

import { DELETE_FAVORITE_SUCCESS, POST_FAVORITE_SUCCESS } from './donor-actions';
import { GET_USER_SUCCESS, USER_LOGIN_SUCCESS } from './user-actions';

const loggedIn = Boolean(store.get(config.auth.idTokenKey));

export default (state = fromJS({ data: { favorites: 0 }, loggedIn, loaded: false }), action) => {
  if (action.type === GET_USER_SUCCESS) {
    return state.merge(fromJS({ data: action.json, loaded: true }));
  } else if (action.type === USER_LOGIN_SUCCESS) {
    return state.set('loggedIn', true);
  } else if (action.type === POST_FAVORITE_SUCCESS) {
    return state.setIn(['data', 'favorites'], state.getIn(['data', 'favorites']) + 1);
  } else if (action.type === DELETE_FAVORITE_SUCCESS) {
    return state.setIn(['data', 'favorites'], state.getIn(['data', 'favorites']) - 1);
  }

  return state;
};
