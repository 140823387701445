const makeAction = (action, status, data) => {
  const newAction = {
    ...action,
    ...{ type: `${action.type}_${status}` },
    ...data,
    originalType: action.type,
  };

  delete newAction.promise;
  return newAction;
};

export default () => next => async action => {
  if (!action.promise) {
    return next(action);
  }

  next(makeAction(action, 'REQUEST'));

  try {
    const response = await action.promise;

    if (response.ok) {
      try {
        const json = await response.json();

        return next(makeAction(action, 'SUCCESS', { response, json }));
      } catch {
        return next(makeAction(action, 'SUCCESS', { response }));
      }
    }

    try {
      const json = await response.json();

      return next(makeAction(action, 'FAILURE', { response, json }));
    } catch {
      return next(makeAction(action, 'FAILURE', { response }));
    }
  } catch (e) {
    next(makeAction(action, 'ERROR', { error: e }));
    console.error(e);
  }
};
