import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';

import DonorCard from '../Donors/DonorCard';
import DonorListItem from '../Donors/DonorListItem';
import DonorLoadMoreButton from '../Donors/DonorLoadMoreButton';
import FavoritesNoResults from './FavoritesNoResults';
import Loading from '../../common/components/Loading';
import { useQuery } from '../../common/utils/hooks';
import { getFavorites } from '../../redux/donor-actions';

const PAGE_SIZE = 150;

const FavoritesList = () => {
  const dispatch = useDispatch();
  const [query] = useQuery();
  const { from, mode } = query.toJS();

  useEffect(() => {
    dispatch(getFavorites(query, PAGE_SIZE));
  }, [dispatch, query]);

  const [donors, firstPage, loaded, total] = useSelector(
    ({ donors: { list } }) => [list.get('data'), list.get('firstPage'), list.get('loaded'), list.get('total')],
    shallowEqual
  );

  let DonorComponent = DonorCard;

  if (mode === 'list') {
    DonorComponent = DonorListItem;
  }

  return (
    <div className="donor-search">
      <h1 className="brandPurple margin-bottom__l">My Favorites</h1>
      {loaded && total > 0 && (
        <h3 className="margin-bottom__m">
          Showing {total} donor{total > 1 && 's'}
        </h3>
      )}
      {firstPage > 0 && <DonorLoadMoreButton from={from - PAGE_SIZE} />}
      {loaded && (
        <div
          className={cn('donor-search__donor-list', {
            'donor-search__donor-list--grid': !mode,
            'donor-search__donor-list--list': mode === 'list',
          })}
        >
          {donors.map(donor => (
            <DonorComponent donor={donor} key={donor.get('donor_id')} />
          ))}
        </div>
      )}
      {loaded && !total && <FavoritesNoResults />}
      {!loaded && (
        <div className="loading-padded">
          <Loading />
        </div>
      )}
      {total > donors.size && <DonorLoadMoreButton from={donors.size} />}
    </div>
  );
};

export default FavoritesList;
