import React from 'react';
import { useHistory } from 'react-router-dom';

import Button from '../../common/components/buttons/button';
import TwebLogoEgg from '../../common/components/logo/logo-colored-egg';

const FavoritesNoResults = () => {
  const history = useHistory();

  return (
    <div className="donor-no-results">
      <div className="margin-bottom__m">
        <TwebLogoEgg />
      </div>
      <h3 className="margin-bottom__xs">No Favorites Yet</h3>
      <p className="margin-bottom__l">Click the heart on your favorite donors to save them here</p>
      <Button buttonType="primary" onClick={() => history.push('/')} className="margin-bottom__s">
        Search For Donors
      </Button>
    </div>
  );
};

export default FavoritesNoResults;
