export default {
  853: 'African American',
  854: 'Alsace',
  855: 'Argentinean',
  856: 'Armenian',
  857: 'Austrian',
  858: 'Belgian',
  859: 'Bolivian',
  860: 'Brazilian',
  861: 'Bulgarian',
  862: 'Canadian',
  863: 'Chilean',
  864: 'Chinese',
  865: 'Colombian',
  866: 'Costa Rican',
  867: 'Creole',
  868: 'Croatian',
  869: 'Czech',
  870: 'Danish',
  871: 'Dutch',
  872: 'East Indian',
  873: 'Ecuadorian',
  874: 'Egyptian',
  875: 'English',
  876: 'Ethiopian',
  877: 'Filipino',
  878: 'Finnish',
  879: 'French',
  880: 'French-Canadian',
  881: 'German',
  882: 'Greek',
  883: 'Guatemalan',
  884: 'Honduran',
  885: 'Hungarian',
  886: 'Icelandic',
  887: 'Iranian',
  888: 'Irish',
  889: 'Israeli',
  890: 'Italian',
  891: 'Jamaican',
  892: 'Japanese',
  893: 'Jewish',
  894: 'Korean',
  895: 'Lebanese',
  896: 'Mexican',
  897: 'Moroccan',
  898: 'Native American',
  899: 'Nicaraguan',
  900: 'Norwegian',
  901: 'Pacific Islander',
  902: 'Pakistani',
  903: 'Palestinian',
  904: 'Panamanian',
  905: 'Paraguayan',
  906: 'Persian',
  907: 'Polish',
  908: 'Portuguese',
  909: 'Puerto Rican',
  910: 'Romanian',
  911: 'Russian',
  912: 'Scandinavian',
  913: 'Scottish',
  914: 'Slavic',
  915: 'Slovakian',
  916: 'South African',
  917: 'Spanish',
  918: 'Swedish',
  919: 'Swiss',
  920: 'Taiwanese',
  921: 'Thai',
  922: 'Turkish',
  923: 'Ukrainian',
  924: 'Uruguayan',
  925: 'Venezuelan',
  926: 'Vietnamese',
  927: 'Welsh',
  928: 'Yugoslavian',
  929: 'Zairian',
  5150: 'A',
  5155: 'AB',
  5160: 'B',
  5165: 'O',
  5100: 'Positive',
  5105: 'Negative',
  1228: 'In US',
  '-1228': 'Outside US',
  33: 'Black',
  34: 'Blue',
  37: 'Brown',
  35: 'Green',
  36: 'Hazel',
  38: 'Black',
  39: 'Blonde',
  40: 'Brown',
  41: 'Red',
  46: 'Average',
  44: 'Curly',
  42: 'Straight',
  47: 'Thick',
  45: 'Thin',
  43: 'Wavy',
  premium: 'Premium Banked',
  'baby-bundle': 'Baby Bundle',
  'family-bundle': 'Family Bundle',
  840: 'Asian',
  841: 'Black',
  843: 'Caucasian',
  844: 'East Indian',
  842: 'Hawaiian',
  849: 'Hispanic',
  850: 'Middle Eastern',
  851: 'Native American',
  693: '4\' 08"',
  694: '4\' 09"',
  695: '4\' 10"',
  696: '4\' 11"',
  697: '5\' 00"',
  698: '5\' 01"',
  699: '5\' 02"',
  700: '5\' 03"',
  701: '5\' 04"',
  702: '5\' 05"',
  703: '5\' 06"',
  704: '5\' 07"',
  705: '5\' 08"',
  706: '5\' 09"',
  707: '5\' 10"',
  708: '5\' 11"',
  709: '6\' 00"',
  710: '6\' 01"',
  711: '6\' 02"',
  712: '6\' 03"',
  713: '6\' 04"',
  762: 'In US',
  761: 'Outside US',
};
