import React from 'react';
import Svg, { Path } from 'svgs';

const Bulb = ({ size, fill }) => {
  return (
    <Svg height={size || 24} width={size || 24} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <Path
        fill={fill || '#FFFFFF'}
        d="M19.36 7H18V5a1.92 1.92 0 0 0-1.83-2H7.83A1.92 1.92 0 0 0 6 5v2H4.64A2.66 2.66 0 0 0 2 9.67v6.66A2.66 2.66 0 0 0 4.64 19h.86a2 2 0 0 0 2 2h9a2 2 0 0 0 2-2h.86A2.66 2.66 0 0 0 22 16.33V9.67A2.66 2.66 0 0 0 19.36 7zM8 5h8v2H8zm-.5 14v-4h9v4zM20 16.33a.66.66 0 0 1-.64.67h-.86v-2a2 2 0 0 0-2-2h-9a2 2 0 0 0-2 2v2h-.86a.66.66 0 0 1-.64-.67V9.67A.66.66 0 0 1 4.64 9h14.72a.66.66 0 0 1 .64.67z"
      />
    </Svg>
  );
};

export default Bulb;
