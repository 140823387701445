import React from 'react';
import Svg, { Path, Defs, Mask, Use, G } from 'svgs';

const TwebLogoEgg = ({ width, height }) => {
  return (
    <Svg xmlns="http://www.w3.org/2000/Svg" height={height || 82} width={width || 64} viewBox="0 0 64 82">
      <Defs>
        <Path id="a" d="M0 0.597834783L15.3156 0.597834783 15.3156 17.4661826 0 17.4661826z" />
      </Defs>
      <G fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <G transform="translate(-824 -472)">
          <G transform="translate(824 472)">
            <Path fill="#095092" d="M10 17c3.881-7.034 8.701-12.915 14-16-3.01 3.665-5.527 9.204-7.29 16H10z" />
            <Path fill="#095092" d="M54 17c-3.148-5.667-6.884-10.65-11-14 2.357 3.565 4.336 8.35 5.803 14H54z" />
            <Path fill="#095092" d="M63 38c-1.412-5.613-3.618-11.52-6.45-17H50c1.125 5.151 1.775 10.893 1.915 17H63z" />
            <Path
              fill="#095092"
              d="M14.123 38c.15-6.107.79-11.849 1.877-17H8.07C5.397 26.393 3.33 32.265 2 38h12.123z"
            />
            <Path
              fill="#095092"
              d="M62.454 59C63.482 55.978 64 53.066 64 50.596c0-2.621-.33-5.546-.89-8.596H51.67C51.594 48.096 51 53.82 50 59h12.454z"
            />
            <Path fill="#095092" d="M49.23 63C47.341 70.84 44.48 77.129 41 81c9.94-2.882 16.51-10.415 20-18H49.23z" />
            <G transform="translate(0 41)">
              <Mask id="b" fill="#fff">
                <Use xlinkHref="#a" />
              </Mask>
              <Path
                fill="#095092"
                d="M15.316 17.466c-1.016-5.14-1.61-10.82-1.684-16.868H.873C.328 3.74 0 6.804 0 9.666c0 2.326.395 5.006 1.212 7.8h14.104z"
              />
            </G>
            <Path fill="#095092" d="M3 63c3.336 8.335 10.214 16.633 22 19-3.678-3.895-6.694-10.573-8.66-19H3z" />
            <Path fill="#095092" d="M34 17h11C42.33 7.574 38.19 1.455 34 0v17z" />
            <Path fill="#095092" d="M31 0c-4.19 1.461-8.331 7.57-11 17h11V0z" />
            <Path fill="#095092" d="M35 38h13c-.136-6.299-.783-12.038-1.839-17H35v17z" />
            <Path fill="#095092" d="M31 21H19.846C18.79 25.962 18.139 31.7 18 38h13V21z" />
            <Path fill="#095092" d="M31 42H18c.067 6.288.683 11.99 1.66 17H31V42z" />
            <Path fill="#D0A053" d="M34 42v17h12.216C47.26 53.99 47.923 48.288 48 42H34z" />
            <Path fill="#095092" d="M35 63v18c4.243-1.496 8.417-8.023 11-18H35z" />
            <Path fill="#095092" d="M31 63H20c2.587 9.984 6.757 16.504 11 18V63z" />
          </G>
        </G>
      </G>
    </Svg>
  );
};

export default TwebLogoEgg;
