import React from 'react';
import Svg, { G, Path, Defs, Mask, Use } from 'svgs';

const FamilyBundle = ({ size, fill }) => {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={size || 24}
      viewBox="0 0 86 90"
    >
      <Defs>
        <Path id="a" d="M.477 0h41.005v38.104H.477z" />
        <Path id="c" d="M0 .572h27.131V16H.001z" />
      </Defs>
      <G fill="none" fillRule="evenodd">
        <G transform="translate(22)">
          <Mask id="b" fill={fill || '#000'}>
            <Use xlinkHref="#a" />
          </Mask>
          <Path
            d="M20.832 38.1l.146-.145.149.15 16.685-16.686c4.893-4.894 4.893-12.855 0-17.749-4.598-4.596-11.903-4.877-16.829-.834-4.927-4.04-12.23-3.76-16.83.834C1.783 6.04.477 9.192.477 12.544c0 3.353 1.306 6.505 3.676 8.875L20.832 38.1z"
            fill={fill || '#000'}
          />
        </G>
        <Path
          d="M64.84 87.954c0 .405-.033.763-.06 1.051l-.013.156-.013.15h21.058l.1-1.19c.024-.252.044-.505.044-.762 0-7.43-7.708-13.476-17.18-13.476-3.6 0-6.942.877-9.707 2.367 3.568 3.068 5.771 7.178 5.771 11.704M68.365 47.825c5.456 0 9.878 5.16 9.878 11.523 0 6.366-4.422 11.525-9.878 11.525-5.455 0-9.878-5.159-9.878-11.525 0-6.364 4.423-11.523 9.878-11.523"
          fill={fill || '#000'}
        />
        <G transform="translate(0 74)">
          <Mask id="d" fill="#fff">
            <Use xlinkHref="#c" />
          </Mask>
          <Path
            d="M22.367 15.161l-.014-.153c-.026-.289-.059-.648-.059-1.054 0-4.123 1.817-7.91 4.837-10.87-2.81-1.575-6.24-2.512-9.95-2.512C7.705.572 0 6.617 0 14.048c0 .257.02.51.043.761L.146 16H22.44l-.072-.839z"
            fill={fill || '#000'}
            mask="url(#d)"
          />
        </G>
        <Path
          d="M16.77 48.513c5.456 0 9.878 5.16 9.878 11.524 0 6.364-4.422 11.523-9.878 11.523s-9.878-5.159-9.878-11.523c0-6.365 4.422-11.524 9.878-11.524M25.681 87.954c0 .31.034.613.06.917h35.652c.026-.305.06-.608.06-.917 0-7.599-8.008-13.759-17.886-13.759s-17.886 6.16-17.886 13.76M43.108 44.844c6.079 0 11.007 5.75 11.007 12.841 0 7.092-4.928 12.841-11.007 12.841-6.08 0-11.007-5.749-11.007-12.84 0-7.093 4.928-12.842 11.007-12.842"
          fill={fill || '#000'}
        />
      </G>
    </Svg>
  );
};

export default FamilyBundle;
